define('debentures-web-public/pods/bookings/view/add-payment-modal/component', ['exports', 'ember-sf-modal/mixins/modal', 'accounting/format-number'], function (exports, _modal, _formatNumber) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modal.default, {
        init: function init() {
            this._super.apply(this, arguments);

            var ewalletDebit = this.get('ewalletBalance');

            if (ewalletDebit != null && ewalletDebit > this.get('transaction.amount')) {
                ewalletDebit = this.get('transaction.amount');
                /*if (this.get('basket.data.discountAmount')) {
                    ewalletDebit += this.get('basket.data.discountAmount');
                    this.set('useEwallet', true);
                };*/
            }
            this.set('ewalletDebit', ewalletDebit);
        },


        ewalletError: Ember.computed("ewalletDebit", function () {
            var ewalletBallance = this.get('ewalletBalance');
            var ewalletDebit = this.get('ewalletDebit');
            if (isNaN(ewalletDebit)) {
                return "Please Enter a valid Number";
            }

            if (parseFloat(ewalletBallance) < parseFloat(ewalletDebit)) {
                return "Ewallet Balance is lower that entered number";
            }

            var result = parseFloat(this.get('transaction.amount').replace(/,/g, ''));

            if (result < parseFloat(ewalletDebit)) {
                return "Amount due lower that entered value.";
            }

            return null;
        }),

        amountDue: Ember.computed('useEwallet', 'ewalletDebit', function () {
            var result = parseFloat(this.get('transaction.amount').replace(/,/g, ''));
            /*let discountAmount = this.get('basket.data.discountAmount');
            result += discountAmount;*/
            if (this.get('useEwallet')) {
                result = (0, _formatNumber.default)(result - this.get('ewalletDebit'), 2);
                if (result < 0) {
                    result = 0;
                }
            }
            return result;
        }),

        actions: {
            addPayment: function addPayment() {
                var _this = this;

                this.get('transaction').amount = this.get('amountDue');

                if (this.get('isAddingPayment')) {
                    return;
                }

                this.setProperties({
                    isAddingPayment: true,
                    errors: null
                });

                if (!this.get("ewalletError")) {
                    this.get('addPayment')(this.get('useEwallet') ? this.get('ewalletDebit') : 0).then(function () {
                        console.log("add... Handler");
                        _this.get('modal').hide();
                    }).catch(function (errors) {
                        _this.set('errors', errors);
                        return errors;
                    }).finally(function () {
                        return _this.set('isAddingPayment', false);
                    });
                }
            }
        }
    });
});