define('debentures-web-public/pods/components/model-select/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        options: null,
        multiple: false,
        prompt: true,
        selectedValues: null,
        selectedObjects: null,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('class', this.get('class') || 'form-control');
            this.set('showPromptOption', this.get('prompt') === true && this.get('multiple') === false);
            this.set('emptyValue', this.get('emptyValue') === undefined ? '' : this.get('emptyValue'));
            this.set('emptyTitle', this.get('emptyTitle') === undefined ? 'Select' : this.get('emptyTitle'));

            // If selectedValues is not passed in - try to re-create it based on selectedObjects.
            var selectedValues = this.get('selectedValues');
            var selectedObjects = this.get('selectedObjects');
            if (Ember.isBlank(selectedValues) && Ember.isPresent(selectedObjects)) {
                this.set('selectedValues', Ember.typeOf(selectedObjects) === 'array' ? selectedObjects.map(function (o) {
                    return o.get('id');
                }) : selectedObjects.get('id'));
            }
        },
        callCallback: function callCallback() {
            if (Ember.isPresent(this.get('valueChanged'))) {
                this.get('valueChanged')(this.get('selectedObjects'));
            }
        },


        actions: {
            selectedValuesChanged: function selectedValuesChanged(values) {
                if (Ember.isBlank(values)) {
                    this.set('selectedObjects', this.get('multiple') ? [] : values);
                    this.callCallback();
                    return;
                }

                var options = this.get('options');
                if (Ember.isPresent(options)) {
                    if (this.get('multiple') === true) {
                        // set array of selected objects
                        var objs = [];
                        values.forEach(function (v) {
                            var obj = options.findBy('id', v);
                            if (Ember.isPresent(obj)) {
                                objs.push(Ember.copy(obj, true));
                            }
                        });

                        this.set('selectedObjects', objs);
                    } else {
                        // set single selected object
                        var obj = options.findBy('id', values);
                        if (Ember.isPresent(obj)) {
                            this.set('selectedObjects', obj);
                        }
                    }

                    this.callCallback();
                }
            }
        }
    });
});