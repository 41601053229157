define('debentures-web-public/pods/components/authenticated-wrapper/component', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var rootURL = _environment.default.rootURL;
    exports.default = Ember.Component.extend({
        router: Ember.inject.service(),
        session: Ember.inject.service(),

        rootURL: rootURL,

        brochureLink: Ember.computed('session', function () {
            var customerLevel = this.get('session').get('currentCustomer').get('level');
            var cdnURL = 'https://d33ztoniv9lbzx.cloudfront.net'; // d33ztoniv9lbzx.cloudfront.net fronts lords-debentures-mt-cdn
            if (customerLevel === 'Bronze') {
                return cdnURL + "/static/pdf/Debenture_InfoBrochure_GrandBronze.pdf";
            } else if (customerLevel === 'Silver') {
                return cdnURL + "/static/pdf/Debenture_InfoBrochure_GrandSilver.pdf";
            } else {
                return cdnURL + "/static/pdf/Debenture_InfoBrochure_Mound_update.pdf";
            }
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            var createItem = function createItem(title, code, route, iconClass, image) {
                var roles = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];

                var activeItem = _this.get('menuItem');

                return {
                    title: title,
                    route: route,
                    iconClass: iconClass,
                    image: image,
                    roles: roles,
                    active: activeItem && activeItem.toLowerCase() === code.toLowerCase()
                };
            };

            /*********************************************************************
             *** PLEASE PRESERVE THE COLUMNS FORMATTING BELOW!
             ********************************************************************/
            this.set('mainMenuItems', [createItem('Fixtures', 'fixtures', 'fixtures', null, 'cricket-ball.png', ['customer'])]);
        },
        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this.$('.tooltips').tooltip();

            Ember.run.scheduleOnce('afterRender', this, function () {
                // Make tables responsive (stackable).
                _this2._initResponsiveSidebarNavigation();

                Ember.$('.footer-btn').click(function () {
                    Ember.$('.footer-our-partners-wrapper').toggleClass('show');
                });
            });
        },
        _initResponsiveSidebarNavigation: function _initResponsiveSidebarNavigation() {
            // Responsive handler for sidebar navigation menu.
            var resizeCallback = function resizeCallback() {
                var width = Ember.$(window).width();

                if (width <= 768) {
                    Ember.$('#container').addClass('sidebar-close');
                    Ember.$('#sidebar > ul').hide();
                } else if (width > 768) {
                    Ember.$('#container').removeClass('sidebar-close');
                    Ember.$('#sidebar > ul').show();
                }
            };

            resizeCallback();
            Ember.$(window).on('resize', resizeCallback);
        },
        _toggleBasketSidebar: function _toggleBasketSidebar(visible, callback) {
            if (!this.get('basketSidebar')) {
                return;
            }

            callback();

            if (visible) {
                this.$('.ds-expand .fa-shopping-basket').css('display', 'block');
                this.$('.ds-expand .fa-chevron-right').css('display', 'none');
            } else {
                this.$('.ds-expand .fa-shopping-basket').css('display', 'none');
                this.$('.ds-expand .fa-chevron-right').css('display', 'block');
            }
        },


        actions: {
            invalidateSession: function invalidateSession() {
                //this.get('session').sendInvalidationRequest();
            },
            toggleNavigationSidebar: function toggleNavigationSidebar() {
                var $navigationSidebar = this.$('#sidebar > ul');

                if ($navigationSidebar.is(":visible")) {
                    $navigationSidebar.hide();
                    this.$('#main-content').css('margin-left', '0px');
                    this.$('#sidebar').css('margin-left', '-2310px');
                    this.$("#container").addClass("sidebar-closed");
                } else {
                    $navigationSidebar.show();
                    this.$('#main-content').css('margin-left', '200px');
                    this.$('#sidebar').css('margin-left', '0');
                    this.$("#container").removeClass("sidebar-closed");
                }
            },
            toggleNormalBasketSidebar: function toggleNormalBasketSidebar() {
                var _this3 = this;

                var $searchSidebar = this.$('.ds');

                this._toggleBasketSidebar($searchSidebar.is(':visible'), function () {
                    if ($searchSidebar.is(':visible')) {
                        $searchSidebar.hide();
                        _this3.$('.main-content-wrap').css('min-width', '100%');
                    } else {
                        $searchSidebar.show();
                        _this3.$('.main-content-wrap').css('min-width', '75%');
                    }
                });
            },
            toggleMobileBasketSidebar: function toggleMobileBasketSidebar() {
                var $offcanvas = this.$('.row-offcanvas');

                this._toggleBasketSidebar($offcanvas.hasClass('active'), function () {
                    $offcanvas.toggleClass('active');

                    // $('#toggle-i .fa-shopping-basket').toggleClass('hide-i');
                    // $('#toggle-i .fa-chevron-right').toggleClass('show-i');
                });
            },
            toggleMobileMenu: function toggleMobileMenu() {
                this.$('#wrapper').toggleClass('toggled');
                this.$('.overlay').toggleClass('show');
            },
            showNewMenu: function showNewMenu() {
                this.$(".meganav.meganav--close").removeClass("meganav--close");
                this.$(".meganavOverlay").addClass("meganavOverlay--show");
            },
            hideNewMenu: function hideNewMenu() {
                this.$(".meganav").addClass("meganav--close");
                this.$(".meganavOverlay.meganavOverlay--show").removeClass("meganavOverlay--show");
            },
            doTransition: function doTransition(route) {
                this.send('toggleMobileMenu');

                this.get('router').transitionTo(route);
            }
        }
    });
});