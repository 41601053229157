define('debentures-web-public/pods/components/alert-messages/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        flashMessages: Ember.inject.service()
    });
});