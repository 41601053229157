define('debentures-web-public/models/seat', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Seat = Ember.Object.extend({
        id: null,
        stand: null,
        row: null,
        block: 0,
        seatNumber: 0
    });

    Seat.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'seats'
        },
        convert: function convert(json) {
            return Seat.create({
                id: json.id,
                stand: json.stand,
                row: json.row,
                block: json.block,
                seatNumber: json.seatNumber
            });
        }
    });

    exports.default = Seat;
});