define('debentures-web-public/models/fixture-date-ticketing', ['exports', 'moment', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/fixture-date', 'debentures-web-public/models/fixture-date-pricepoint', 'debentures-web-public/models/fixture-date-seat'], function (exports, _moment, _jsonapiModel, _fixtureDate, _fixtureDatePricepoint, _fixtureDateSeat) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureTicketing = Ember.Object.extend(Ember.Copyable, {
        id: null,

        fixtureDate: null,
        seats: null,
        pricePoints: null,

        additionalPaymentPhaseStart: null,
        additionalPaymentPhaseStartTime: null,
        additionalPaymentPhaseEnd: null,
        additionalPaymentPhaseEndTime: null,
        bookingPhaseStart: null,
        bookingPhaseStartTime: null,
        bookingPhaseEnd: null,
        bookingPhaseEndTime: null,

        isNew: Ember.computed('id', function () {
            return Ember.isEmpty(this.get('id'));
        }),

        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);
            var bookingPhaseStartDate = this.get('bookingPhaseStart');
            var bookingPhaseEndDate = this.get('bookingPhaseEnd');
            var additionalPaymentPhaseStartDate = this.get('additionalPaymentPhaseStart');
            var additionalPaymentPhaseEndDate = this.get('additionalPaymentPhaseEnd');
            ret.set('additionalPaymentPhaseStart', additionalPaymentPhaseStartDate == null ? null : (0, _moment.default)(additionalPaymentPhaseStartDate).format('YYYY-MM-DD'));
            ret.set('additionalPaymentPhaseEnd', additionalPaymentPhaseEndDate == null ? null : (0, _moment.default)(additionalPaymentPhaseEndDate).format('YYYY-MM-DD'));
            ret.set('bookingPhaseStart', bookingPhaseStartDate == null ? null : (0, _moment.default)(bookingPhaseStartDate).format('YYYY-MM-DD'));
            ret.set('bookingPhaseEnd', bookingPhaseEndDate == null ? null : (0, _moment.default)(bookingPhaseEndDate).format('YYYY-MM-DD'));
            return ret;
        },
        fromJSON: function fromJSON() {
            var date = this.get('bookingPhaseStart');
            this.set('bookingPhaseStart', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('bookingPhaseStartTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            date = this.get('bookingPhaseEnd');
            this.set('bookingPhaseEnd', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('bookingPhaseEndTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            date = this.get('additionalPaymentPhaseStart');
            this.set('additionalPaymentPhaseStart', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('additionalPaymentPhaseStartTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            date = this.get('additionalPaymentPhaseEnd');
            this.set('additionalPaymentPhaseEnd', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('additionalPaymentPhaseEndTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            return this;
        }
    });

    FixtureTicketing.reopen({
        copy: function copy() {
            return FixtureTicketing.create(this.getProperties(Object.keys(this)));
        }
    });

    FixtureTicketing.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-ticketing',
            relationships: {
                fixtureDate: _fixtureDate.default,
                pricePoints: _fixtureDatePricepoint.default,
                seats: _fixtureDateSeat.default
            }
        }
    });

    exports.default = FixtureTicketing;
});