define('debentures-web-public/pods/bookings/index/route', ['exports', 'debentures-web-public/config/environment', 'ember-sf-pageable/mixins/pageable-route', 'debentures-web-public/models/booking'], function (exports, _environment, _pageableRoute, _booking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend(_pageableRoute.default, {
        session: Ember.inject.service(),

        pageableModel: function pageableModel() {
            return {
                bookings: this.getBookings()
            };
        },
        organizeBookings: function organizeBookings(data) {
            data.forEach(function (booking) {
                booking.set("fixturesValues", {});
                booking.bookingGroups.forEach(function (bookingGroup) {
                    var groupMeta = bookingGroup.groupMeta;
                    var eventInfo = groupMeta.eventInfo;
                    booking.get("fixturesValues")[eventInfo.id] = { date: eventInfo.date, name: eventInfo.name };
                });
                booking.fixturesValues = Object.values(booking.fixturesValues);
                booking.fixturesValues.sort(function (a, b) {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                });
            });

            data.sort(function (a, b) {
                return new Date(a.fixturesValues[0].date).getTime() - new Date(b.fixturesValues[0].date).getTime();
            });
        },
        getBookings: function getBookings() {
            var _this = this;

            var customer = this.get('session').get('currentCustomer');
            return this.loadPage('bookings', _booking.default, SERVICES.bookings + '/bookings' + '?filter[status]=AUTHORISED,PENDING,HARD_PENCIL,CANCELLED' + ('&filter[customerId]=' + customer.id)).then(function (bookingsPage) {

                bookingsPage.data.forEach(function (b) {
                    return b.set('customer', customer);
                });
                _this.organizeBookings(bookingsPage.data);
                return bookingsPage;
            });
        }
    });
});