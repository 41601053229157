define('debentures-web-public/models/restaurant', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Restaurant = Ember.Object.extend({
        id: null,
        name: null,
        capacity: null,
        standId: null,
        standName: null,
        firstTableNumber: null,
        tableCount: null
    });

    Restaurant.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'restaurants'
        }
    });

    exports.default = Restaurant;
});