define('debentures-web-public/pods/fixtures/view-date/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/fixture-date', 'debentures-web-public/models/booking', 'debentures-web-public/models/booking-fixture-date-ref'], function (exports, _environment, _fixtureDate, _booking, _bookingFixtureDateRef) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),

        model: function model(_ref) {
            var _this = this;

            var fixture_date_id = _ref.fixture_date_id;

            var promises = Ember.RSVP.all([this.get('ajax').fetchModel(_fixtureDate.default, SERVICES.fixtures + '/date/' + fixture_date_id), this.get('ajax').fetchList(_booking.default, SERVICES.bookings + '/bookings' + '?filter[status]=AUTHORISED,PENDING,HARD_PENCIL' + ('&filter[eventId]=' + fixture_date_id)).then(function (bookings) {
                return bookings.length === 0 ? null : bookings[0];
            })]);

            return promises.then(function (_ref2) {
                var _ref3 = _slicedToArray(_ref2, 2),
                    fixtureDate = _ref3[0],
                    booking = _ref3[1];

                return _this.get('ajax').fetchList(_bookingFixtureDateRef.default, SERVICES.bookingRefs + '/' + booking.id + '/fixture-date-refs').then(function (fdRefs) {
                    return {
                        fixtureDate: fixtureDate,
                        booking: booking,
                        bookingGroup: booking.get('bookingGroups').findBy('bookingGroup', fixtureDate.get('id')),
                        fixtureDateRef: fdRefs.findBy('fixtureDateId', fixtureDate.get('id'))
                    };
                });
            });
        }
    });
});