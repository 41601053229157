define('debentures-web-public/models/customer', ['exports', 'ember-i18n-iso-countries', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/customer-address', 'moment'], function (exports, _emberI18nIsoCountries, _jsonapiModel, _customerAddress, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var __addAddressIfRequired = function __addAddressIfRequired(addresses, type) {
        if (!addresses.isAny('type', type)) {
            addresses.pushObject(_customerAddress.default.create({
                type: type,
                default: true,
                countryId: 'GB'
            }));
        }
    };
    var __initAddresses = function __initAddresses(customer) {
        var addresses = customer.get('addresses') || [];
        __addAddressIfRequired(addresses, 'MAIN');
        customer.set('addresses', addresses.map(function (a) {
            return _customerAddress.default.create(a);
        }));
    };

    var Customer = Ember.Object.extend(Ember.Copyable, {
        id: null,
        title: null,
        firstName: null,
        lastName: null,
        addresses: null,
        email: null,
        company: null,
        phone: null,
        dateOfBirth: null,
        active: null,
        optin: null,
        externalReference: null,

        // Used for customer registration & password change.
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,

        init: function init() {
            this._super.apply(this, arguments);
            __initAddresses(this);
        },


        exists: Ember.computed('id', function () {
            return Ember.isPresent(this.get('id'));
        }),
        fullName: Ember.computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }),
        mainAddress: Ember.computed('addresses', function () {
            return this.get('addresses').findBy('type', 'MAIN');
        }),
        countryName: Ember.computed(function () {
            return (0, _emberI18nIsoCountries.getNames)('en')[this.get('mainAddress').countryId];
        }),

        status: Ember.computed('active', function () {
            return this.get('active') ? 'ACTIVE' : 'INACTIVE';
        }),
        statusClass: Ember.computed('active', function () {
            return this.get('active') ? 'is-active' : 'not-active';
        }),
        statusTextClass: Ember.computed('status', function () {
            return this.get('active') ? 'green' : 'grey';
        }),
        isInactive: Ember.computed('status', function () {
            return this.get('status') === 'INACTIVE';
        }),
        isActive: Ember.computed('status', function () {
            return this.get('status') === 'ACTIVE';
        }),

        fromJSON: function fromJSON() {
            var ret = Ember.copy(this, true);

            var dateOfBirth = this.get('dateOfBirth');
            ret.set('dateOfBirth', Ember.isBlank(dateOfBirth) ? null : (0, _moment.default)(dateOfBirth, 'YYYY-MM-DD').toDate());

            __initAddresses(ret);

            return ret;
        },
        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);
            var addresses = this.get('addresses').map(function (a) {
                return Ember.copy(a, true);
            });

            ret.set('dateOfBirth', Ember.isBlank(this.get('dateOfBirth')) ? null : (0, _moment.default)(this.get('dateOfBirth')).format('YYYY-MM-DD'));
            ret.set('addresses', addresses.filter(function (a) {
                return !a.isEmpty();
            }));
            return ret;
        }
    });

    Customer.reopen({
        copy: function copy() {
            return Customer.create(this.getProperties(Object.keys(this)));
        }
    });

    Customer.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'customers'
        }
    });

    exports.default = Customer;
});