define('debentures-web-public/pods/components/top-basket-info/component', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var rootURL = _environment.default.rootURL,
        returnURL = _environment.default.returnURL,
        editUserURL = _environment.default.editUserURL;
    exports.default = Ember.Component.extend({
        tagName: '',

        basket: Ember.inject.service(),
        session: Ember.inject.service(),

        rootURL: rootURL,
        returnURL: returnURL,
        editUserURL: editUserURL,

        actions: {
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
                //this.get('session').sendInvalidationRequest();
            }
        }
    });
});