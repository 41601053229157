define('debentures-web-public/utils/ui', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.animateScroll = animateScroll;
    function animateScroll(elementSelector) {
        var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -15;

        Ember.$('html, body').animate({
            scrollTop: Ember.$(elementSelector).offset().top + offset
        });
    }
});