define('debentures-web-public/models/refund', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/transaction'], function (exports, _jsonapiModel, _transaction) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Refund = Ember.Object.extend({
        orderId: null,
        parent: null,
        refundType: null,
        amount: null,
        reason: null,

        toJSON: function toJSON() {
            var ret = Refund.create({
                orderId: this.get('orderId'),
                parent: this.get('parent'),
                refundType: this.get('refundType'),
                reason: this.get('reason')
            });

            if (this.get('refundType') === 'PARTIAL') {
                var amount = this.get('amount');

                ret.set('amount', !Number.isNaN(amount) && amount !== '' ? Number(amount) : null);
            }

            return ret;
        }
    });

    Refund.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'refunds',
            relationships: {
                parent: _transaction.default
            }
        }
    });

    exports.default = Refund;
});