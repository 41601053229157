define('debentures-web-public/models/fixture-date-seat', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/fixture-date-ticketing', 'debentures-web-public/models/seat'], function (exports, _jsonapiModel, _fixtureDateTicketing, _seat) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureSeat = Ember.Object.extend({
        id: null,
        seat: null,
        status: null,
        ticketing: null
    });

    FixtureSeat.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-seats',
            relationships: {
                seat: _seat.default,
                ticketing: _fixtureDateTicketing.default
            }
        }
    });

    exports.default = FixtureSeat;
});