define('debentures-web-public/models/seating-plan', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var SeatingPlan = Ember.Object.extend({
        id: null,
        name: null,
        fixturesCount: null,
        seatsCount: null,
        created: null,
        createdBy: null
    });

    SeatingPlan.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'seating-plans'
        }
    });

    exports.default = SeatingPlan;
});