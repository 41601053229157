define('debentures-web-public/pods/book/select-products/ticket-options/component', ['exports', 'debentures-web-public/utils/range', 'debentures-web-public/utils/ui'], function (exports, _range, _ui) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        settings: Ember.inject.service(),

        errorOnSeatSelection: Ember.observer('errors.{noSeatSelected,status,customization,productGroup}', function () {
            var errors = this.get('errors') || {};
            var errorsCount = Object.keys(errors).length;

            if (errorsCount > 0) {
                Ember.run.once(function () {
                    (0, _ui.animateScroll)('#errorsTop');
                });
            }
        }),

        init: function init() {
            this._super.apply(this, arguments);

            var maxAdditionalSeats = this.get('settings.globals.maxAdditionalSeats');

            this.set('additionalQuantities', (0, _range.range)(1, maxAdditionalSeats));
        }
    });
});