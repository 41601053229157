define('debentures-web-public/pods/book/payment-centre/payment-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basket: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            this.set('paymentType', 'LATER');

            var ewalletDebit = this.get('ewalletBalance');

            if (ewalletDebit > this.get('basket.data.total')) {
                ewalletDebit = this.get('basket.data.total');
                if (this.get('basket.data.discountAmount')) {
                    ewalletDebit += this.get('basket.data.discountAmount');
                    this.set('useEwallet', true);
                };
            }
            this.set('ewalletDebit', ewalletDebit);
        },


        amountDue: Ember.computed('useEwallet', 'ewalletDebit', function () {
            var result = this.get('basket.data.total');
            var discountAmount = this.get('basket.data.discountAmount');
            result += discountAmount;
            if (this.get('useEwallet')) {
                result -= this.get('ewalletDebit');
                if (result < 0) {
                    result = 0;
                }
            }
            return result;
        }),

        actions: {
            ewalletAmountChanged: function ewalletAmountChanged(event) {
                var amountValue = event.target.value;
                if (!Number.isNaN(amountValue) && amountValue !== '') {
                    var amount = Number(amountValue);
                    if (amount > this.get('ewalletBalance')) {
                        amount = this.get('ewalletBalance');
                    }
                    if (amount > this.get('basket.data.total')) {
                        amount = this.get('basket.data.total');
                    }
                    this.set('ewalletDebit', amount);
                }
            },
            makePayment: function makePayment() {
                var _this = this;

                if (this.get('inProgress')) {
                    return;
                }

                this.setProperties({
                    inProgress: true,
                    errors: null
                });

                this.get('makePayment')(this.get('paymentType'), this.get('useEwallet') ? this.get('ewalletDebit') : null).catch(function (errors) {
                    _this.setProperties({
                        inProgress: false,
                        errors: errors
                    });

                    return errors;
                });
            }
        }
    });
});