define('debentures-web-public/models/product', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Product = Ember.Object.extend(Ember.Copyable, {
        id: null,
        productType: null,
        productGroup: null,
        originId: null,
        name: null,
        description: null,
        iconUrl: null,
        price: null,
        currency: null,
        inventory: null,
        available: null,
        meta: null,
        deliveryOptions: null
    });

    Product.reopen({
        copy: function copy(deep) {
            var ret = Product.create(this.getProperties(Object.keys(this)));

            if (deep) {
                ret.set('meta', Ember.assign({}, this.get('meta')));
            }

            return ret;
        }
    });

    Product.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'products'
        }
    });

    exports.default = Product;
});