define('debentures-web-public/pods/my-account/overview/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/customer'], function (exports, _environment, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),

        model: function model() {
            return Ember.copy(this.get('session').get('currentCustomer'), true);
        },


        actions: {
            saveChanges: function saveChanges() {
                var _this = this;

                this.set('currentModel.errors', null);
                var customer = this.get('currentModel');
                var data = _customer.default.stringify({
                    email: customer.get('email'),
                    phone: customer.get('phone')
                });

                this.get('ajax').patch(SERVICES.customers + '/customers/' + customer.id, data, _customer.default).then(function (customer) {
                    _this.get('flashMessages').success('Your personal info has been successfully updated');
                    _this.get('session').refreshCustomer(customer);
                    window.scrollTo(0, 0);
                }).catch(function (errors) {
                    _this.set('currentModel.errors', errors);

                    return errors;
                });
            }
        }
    });
});