define('debentures-web-public/models/fixture-date-package', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/fixture-date-service', 'debentures-web-public/models/product'], function (exports, _jsonapiModel, _fixtureDateService, _product) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureDatePackage = Ember.Object.extend(Ember.Copyable, {
        id: null,

        packageId: null,
        price: null,
        onSale: null,

        statusText: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'On Sale' : 'Off Sale';
        }),

        statusClass: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'is-active' : 'is-disabled';
        }),

        statusBarClass: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'green' : 'disabled';
        }),

        toJSON: function toJSON() {
            var result = Ember.copy(this, true);
            result.set('price', Ember.isBlank(this.get('price')) ? null : parseFloat(this.get('price')));
            return result;
        }
    });

    FixtureDatePackage.reopen({
        copy: function copy() {
            return FixtureDatePackage.create(this.getProperties(Object.keys(this)));
        }
    });

    FixtureDatePackage.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-restaurant-packages',
            relationships: {
                services: _fixtureDateService.default,
                product: _product.default
            }
        }
    });

    exports.default = FixtureDatePackage;
});