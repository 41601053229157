define('debentures-web-public/pods/dining/add-packages-modal/component', ['exports', 'ember-sf-modal/mixins/modal'], function (exports, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modal.default, {
        init: function init() {
            this._super.apply(this, arguments);
        },


        actions: {
            addPackages: function addPackages() {
                this.get('addPackages')();

                this.get('modal').hide();
            }
        }
    });
});