define('debentures-web-public/models/restaurant-service', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/restaurant'], function (exports, _jsonapiModel, _restaurant) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var RestaurantService = Ember.Object.extend(Ember.Copyable, {
        id: null,
        name: null,
        description: null,
        iconUrl: null,
        capacity: null,
        restaurant: null,
        flexibleSitting: null,
        firstSitting: null,
        lastSitting: null,

        toJSON: function toJSON() {
            var result = Ember.copy(this, true);
            result.set('capacity', Ember.isBlank(this.get('capacity')) ? null : parseInt(this.get('capacity')));
            return result;
        }
    });

    RestaurantService.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'restaurant-services',
            relationships: {
                restaurant: _restaurant.default
            }
        }
    });

    RestaurantService.reopen({
        copy: function copy() {
            return RestaurantService.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = RestaurantService;
});