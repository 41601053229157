define('debentures-web-public/pods/book/quick-booking/seats-select/component', ['exports', 'debentures-web-public/utils/collectors'], function (exports, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        options: Ember.computed('bookingGroup.items.[]', function () {
            return (0, _collectors.groupBy)(this.get('bookingGroup.items'), function (it) {
                return it.get('product.id');
            });
        }),
        productsMap: Ember.computed('bookingGroup.items.[]', function () {
            return (0, _collectors.hashMap)(this.get('bookingGroup.items'), function (it) {
                return it.get('product.id');
            }, function (it) {
                return it.get('product');
            });
        }),

        init: function init() {
            this._super.apply(this, arguments);

            this.set('selectedSeats', (this.get('bookingGroup.items') || []).filter(function (it) {
                return it.get('selected');
            }));
        },


        actions: {
            selectSeats: function selectSeats(items) {
                var itemsBySeatId = (0, _collectors.hashMap)(items, function (it) {
                    return it.get('debenture.seat.id');
                });

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = this.get('bookingGroup.items')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var item = _step.value;

                        item.set('selected', !!itemsBySeatId[item.get('debenture.seat.id')]);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                this.set('selectedSeats', items);
            }
        }
    });
});