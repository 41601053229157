define('debentures-web-public/helpers/has-any-role', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Helper.extend({
        session: Ember.inject.service(),

        compute: function compute(_ref /*, hash*/) {
            var _ref2 = _slicedToArray(_ref, 1),
                roles = _ref2[0];

            if (!this.get('session.isAuthenticated')) {
                return false;
            }
            if (Ember.isBlank(roles)) {
                return true;
            }

            var requiredRoles = void 0;
            if (Ember.typeOf(roles) === 'string') {
                requiredRoles = roles.split(/\s*,\s*/);
            } else if (Ember.typeOf(roles) === 'array') {
                requiredRoles = roles;
            } else {
                throw new Ember.Error("The parameter 'roles' should be either a comma separated string or an array");
            }

            var foundRole = this.get('session.currentUser.roles').find(function (role) {
                return requiredRoles.indexOf(role) !== -1;
            });

            return !!foundRole;
        }
    });
});