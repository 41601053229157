define('debentures-web-public/utils/collectors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.flatMap = flatMap;
    exports.unique = unique;
    exports.hashMap = hashMap;
    exports.groupBy = groupBy;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    /* global Set */

    /**
     * Convert two-dimensional array into one-dimensional array.
     *
     * [[1,2],[3,4],[5]] => [1,2,3,4,5]
     */
    function flatMap(array) {
        var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return v;
        };

        if (!array) {
            return [];
        }

        return array.reduce(function (accum, item) {
            accum.pushObjects(fn(item));
            return accum;
        }, []);
    }

    /**
     * Collect unique elements from an array.
     *
     * [1,2,1,3,4,2] => [1,2,3,4]
     *
     * Note: First consider using Ember's ArrayProxy.uniq/uniqBy.
     */
    function unique(array) {
        var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return v;
        };

        if (!array) {
            return [];
        }

        return [].concat(_toConsumableArray(array.reduce(function (accum, item) {
            accum.add(fn(item));
            return accum;
        }, new Set())));
    }

    /**
     * Convert array into a hash map.
     *
     * [{id:1,value:'1'},{id:2,value:'2'}]
     *     => {1: {id:1,value:'1'}, 2: {id:2,value:'2'} }
     */
    function hashMap(array) {
        var keyFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return Ember.get(v, 'id');
        };
        var valueFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (v) {
            return v;
        };

        if (!array) {
            return {};
        }

        return array.reduce(function (accum, item) {
            var key = keyFn(item);
            var value = valueFn(item);

            if (Ember.typeOf(key) === 'array') {
                // If multiple keys - add each into the map.
                key.forEach(function (k) {
                    accum[k] = value;
                });
            } else {
                accum[key] = value;
            }

            return accum;
        }, {});
    }

    /**
     * Group array values by key (field). It's like hashMap, but each key may have more than one value.
     *
     * [{stand:'UM',value:'1'},{stand:'UM',value:'2'},{stand:'UG',value:'3'}]
     *     => { 'UM': [ {stand:'UM',value:'1'}, {stand:'UM',value:'2'}, ],
     *          'UG': [ {stand:'UG',value:'3'}] ]}
     */
    function groupBy(array) {
        var keyFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return Ember.get(v, 'id');
        };
        var valueFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (v) {
            return v;
        };

        if (!array) {
            return {};
        }

        return array.reduce(function (accum, item) {
            var key = keyFn(item);
            var value = valueFn(item);

            if (accum[key]) {
                accum[key].push(value);
            } else {
                accum[key] = [value];
            }

            return accum;
        }, {});
    }
});