define('debentures-web-public/models/client', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Client = Ember.Object.extend({
        id: null,
        name: null,
        logoUrl: null,
        contactTitle: null,
        contactFirstName: null,
        contactLastName: null,
        contactAddress1: null,
        contactAddress2: null,
        contactTown: null,
        contactPostcode: null,
        contactCountryId: null,
        email: null,
        phone: null
    });

    Client.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'clients'
        }
    });

    exports.default = Client;
});