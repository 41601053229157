define('debentures-web-public/models/fixture-date-settings', ['exports', 'moment', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/fixture-date'], function (exports, _moment, _jsonapiModel, _fixtureDate) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureRestaurantSettings = Ember.Object.extend(Ember.Copyable, {
        id: null,
        requestPhaseStart: null,
        requestPhaseStartTime: null,
        requestPhaseEnd: null,
        requestPhaseEndTime: null,

        isNew: Ember.computed('id', function () {
            return Ember.isEmpty(this.get('id'));
        }),

        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);
            var requestPhaseStartDate = this.get('requestPhaseStart');
            var requestPhaseEndDate = this.get('requestPhaseEnd');
            ret.set('requestPhaseStart', requestPhaseStartDate == null ? null : (0, _moment.default)(requestPhaseStartDate).format('YYYY-MM-DD'));
            ret.set('requestPhaseEnd', requestPhaseEndDate == null ? null : (0, _moment.default)(requestPhaseEndDate).format('YYYY-MM-DD'));
            return ret;
        },
        fromJSON: function fromJSON() {
            var date = this.get('requestPhaseStart');
            this.set('requestPhaseStart', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('requestPhaseStartTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            date = this.get('requestPhaseEnd');
            this.set('requestPhaseEnd', Ember.isBlank(date) ? null : (0, _moment.default)(date, 'YYYY-MM-DD').toDate());
            this.set('requestPhaseEndTime', Ember.isBlank(date) ? null : (0, _moment.default)(date).utc().format('HH:mm'));
            return this;
        }
    });

    FixtureRestaurantSettings.reopen({
        copy: function copy() {
            return FixtureRestaurantSettings.create(this.getProperties(Object.keys(this)));
        }
    });

    FixtureRestaurantSettings.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-restaurant-settings',
            relationships: {
                fixtureDate: _fixtureDate.default
            }
        }
    });

    exports.default = FixtureRestaurantSettings;
});