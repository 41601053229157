define('debentures-web-public/pods/dining/dining-options/component', ['exports', 'debentures-web-public/utils/range', 'debentures-web-public/models/basket-item', 'debentures-web-public/models/basket-item-customization'], function (exports, _range, _basketItem, _basketItemCustomization) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        selectedPackages: null,
        packagesSelected: false,

        modal: Ember.inject.service(),

        quantityOptions: Ember.computed('ticketsQuantity', function () {
            return (0, _range.range)(1, this.get('ticketsQuantity'));
        }),

        restaurants: Ember.computed('options.[]', function () {

            this.get('options').sort(function (i1, i2) {
                var sitting1 = i1.get('meta.firstSitting');
                var sitting2 = i2.get('meta.firstSitting');

                if (sitting1 == null && sitting2 != null || sitting1 > sitting2) {
                    return 1;
                } else if (sitting2 == null && sitting1 != null || sitting1 < sitting2) {
                    return -1;
                } else {
                    return i1.get('price') - i2.get('price');
                }
            });

            var availableRestaurants = this.get('availableRestaurants');
            var savedRestaurant = this.get('savedRestaurant');
            console.log("OPTIONS=" + JSON.stringify(this.get('options')));
            console.log("SAVED:" + JSON.stringify(savedRestaurant));
            console.log("availableRestaurants:" + JSON.stringify(availableRestaurants));
            var restaurantsMap = this.get('options').filter(function (p) {
                return savedRestaurant || availableRestaurants.includes(p.get('meta.restaurant.id'));
            }).reduce(function (accum, opt) {
                var rest = opt.get('meta.restaurant');
                if (accum[rest.id]) {
                    accum[rest.id].options.push(opt);
                } else {
                    accum[rest.id] = Ember.assign({}, rest, {
                        options: [opt]
                    });
                }

                return accum;
            }, {});

            return Object.values(restaurantsMap);
        }),
        isSingleRestaurant: Ember.computed('restaurants.[]', function () {
            return this.get('restaurants').length === 1;
        }),
        altRestaurants: Ember.computed('restaurants.[]', 'selectedRestaurant', function () {
            var selected = this.get('selectedRestaurant');

            var ret = !selected ? [] : this.get('restaurants').filter(function (r) {
                return r.id !== selected.id;
            });
            return ret;
        }),
        secondAltRestaurants: Ember.computed('restaurants.[]', 'selectedRestaurant', 'groupInfo.altRestaurant', function () {
            var alt = this.get('groupInfo.altRestaurant');
            var main = this.get('selectedRestaurant');
            return !alt ? [] : this.get('restaurants').filter(function (r) {
                return r.id !== main.id && r.id !== alt.id;
            });
        }),

        isConfirmRequestAvailable: Ember.computed('groupInfo.altRestaurant', 'packagesSelected', 'isSingleRestaurant', function () {
            return this.get('packagesSelected') && (this.get('isSingleRestaurant') || this.get('groupInfo.altRestaurant'));
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            if (this.get('groupInfo.noDiningRequired')) {
                this.set('isShowDiningOptions', this.get('groupInfo.noDiningRequired'));
                //return;
            }

            if (this.get('isSingleRestaurant')) {
                this.set('selectedRestaurant', this.get('restaurants')[0]);
            } else {
                var newSelectedRestaurant = null;

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = this.get('restaurants')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var rest = _step.value;

                        if (newSelectedRestaurant) {
                            break;
                        }

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = rest.options[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var item = _step2.value;

                                if (item.get('selected')) {
                                    newSelectedRestaurant = rest;
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                if (newSelectedRestaurant) {
                    this.setProperties({
                        selectedRestaurant: newSelectedRestaurant,
                        isShowDiningOptions: true
                    });
                }
            }

            /*if (this.get('groupInfo.altRestaurant')) {
                const altRestaurant = this.get('groupInfo.altRestaurant');
                 this.set('groupInfo.altRestaurant', this.get('altRestaurants').findBy('id', altRestaurant.id));
            }*/
        },
        setItemQuantity: function setItemQuantity(item, quantity) {
            item.set('selected', quantity > 0);
            item.set('quantity', quantity);
        },


        actions: {
            showDiningOptions: function showDiningOptions() {
                this.set('isShowDiningOptions', true);
            },
            toggleDiningRequired: function toggleDiningRequired(value) {
                if (value != null) {
                    this.set('groupInfo.noDiningRequired', value);
                } else {
                    this.set('groupInfo.noDiningRequired', true);
                }

                this.set('isShowDiningOptions', this.get('groupInfo.noDiningRequired'));
            },
            changeRestaurant: function changeRestaurant(rest) {
                this.set('selectedRestaurant', rest);

                // Reset quantity selection on all other restaurant packages.
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = this.get('restaurants')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var r = _step3.value;

                        if (!rest || rest.id !== r.id) {
                            var _iteratorNormalCompletion4 = true;
                            var _didIteratorError4 = false;
                            var _iteratorError4 = undefined;

                            try {
                                for (var _iterator4 = r.options[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                                    var item = _step4.value;

                                    this.setItemQuantity(item, 0);
                                }
                            } catch (err) {
                                _didIteratorError4 = true;
                                _iteratorError4 = err;
                            } finally {
                                try {
                                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                                        _iterator4.return();
                                    }
                                } finally {
                                    if (_didIteratorError4) {
                                        throw _iteratorError4;
                                    }
                                }
                            }
                        }
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }

                this.set('packagesSelected', false);
                this.set('groupInfo.altRestaurant', null);
                //this.refresh();
            },
            changeAltRestaurant: function changeAltRestaurant(rest) {
                this.set('groupInfo.altRestaurant', rest);
                this.set('groupInfo.secondAltRestaurant', null);
            },
            changeQuantity: function changeQuantity(item, quantity) {
                this.setItemQuantity(item, quantity);
            },
            addPackages: function addPackages(bg) {
                var _this = this;

                var booking = this.get('currentModel.booking');
                var params = {
                    selectedRestaurant: this.get('selectedRestaurant'),
                    selectedPackages: this.get('selectedPackages'),
                    quantityOptions: this.get('quantityOptions'),
                    specialRequests: this.get('groupInfo.specialRequests.dietary') };
                this.get('modal').show('dining/add-packages-modal', {
                    params: params,
                    addPackages: function addPackages() {
                        _this.set('groupInfo.specialRequests.dietary', params.specialRequests);

                        var selected = false;
                        var _iteratorNormalCompletion5 = true;
                        var _didIteratorError5 = false;
                        var _iteratorError5 = undefined;

                        try {
                            for (var _iterator5 = params.selectedRestaurant.options[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                                var pack = _step5.value;

                                if (pack.quantity > 0) {
                                    selected = true;
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError5 = true;
                            _iteratorError5 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion5 && _iterator5.return) {
                                    _iterator5.return();
                                }
                            } finally {
                                if (_didIteratorError5) {
                                    throw _iteratorError5;
                                }
                            }
                        }

                        _this.set('packagesSelected', selected);
                    }
                });
            },
            addDining: function addDining(items) {
                var _this2 = this;

                var booking = this.get('booking');
                var groupId = this.get('groupId');

                var unallocatedDiningQty = 0;
                var bookingGroup = void 0;

                var _iteratorNormalCompletion6 = true;
                var _didIteratorError6 = false;
                var _iteratorError6 = undefined;

                try {
                    for (var _iterator6 = booking.bookingGroups[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                        var bg = _step6.value;

                        if (bg.bookingGroup === groupId) {
                            bookingGroup = bg;
                            var _iteratorNormalCompletion7 = true;
                            var _didIteratorError7 = false;
                            var _iteratorError7 = undefined;

                            try {
                                for (var _iterator7 = items[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                                    var item = _step7.value;

                                    if (item.quantity > 0) {
                                        (function () {
                                            var packages = _this2.get('selectedRestaurant.options');
                                            var product = null;
                                            var _iteratorNormalCompletion8 = true;
                                            var _didIteratorError8 = false;
                                            var _iteratorError8 = undefined;

                                            try {
                                                for (var _iterator8 = packages[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
                                                    var pkg = _step8.value;

                                                    if (pkg.id === item.id) {
                                                        product = pkg;
                                                        break;
                                                    }
                                                }
                                            } catch (err) {
                                                _didIteratorError8 = true;
                                                _iteratorError8 = err;
                                            } finally {
                                                try {
                                                    if (!_iteratorNormalCompletion8 && _iterator8.return) {
                                                        _iterator8.return();
                                                    }
                                                } finally {
                                                    if (_didIteratorError8) {
                                                        throw _iteratorError8;
                                                    }
                                                }
                                            }

                                            var meta = Ember.assign({ group: bg.groupMeta }, product.get('meta'), {
                                                iconUrl: product.get('iconUrl')
                                            });

                                            var packageSize = _this2.get('packages').find(function (p) {
                                                return p.get('product.id') === product.id;
                                            }).get('services').length;

                                            var quantity = parseInt(item.quantity);

                                            unallocatedDiningQty += quantity * packageSize;

                                            var newItem = _basketItem.default.create({
                                                name: product.name,
                                                product: product.id,
                                                quantity: quantity,
                                                bookingGroup: groupId,
                                                event: product.availableFor,
                                                meta: meta,
                                                productType: 'SINGLE',
                                                productGroup: 'Dining',
                                                customization: _basketItemCustomization.default.create({
                                                    restaurant: product.get('meta.restaurant')
                                                })
                                            });

                                            bg.items.pushObject(newItem);
                                            booking.items.pushObject(newItem);
                                        })();
                                    }
                                }
                            } catch (err) {
                                _didIteratorError7 = true;
                                _iteratorError7 = err;
                            } finally {
                                try {
                                    if (!_iteratorNormalCompletion7 && _iterator7.return) {
                                        _iterator7.return();
                                    }
                                } finally {
                                    if (_didIteratorError7) {
                                        throw _iteratorError7;
                                    }
                                }
                            }
                        }
                    }
                } catch (err) {
                    _didIteratorError6 = true;
                    _iteratorError6 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion6 && _iterator6.return) {
                            _iterator6.return();
                        }
                    } finally {
                        if (_didIteratorError6) {
                            throw _iteratorError6;
                        }
                    }
                }

                this.get('modal').showConfirmation('Confirmation', 'Are you sure you want to confirm your selection?', function () {
                    var altRestaurant = _this2.get('groupInfo.altRestaurant');
                    if (altRestaurant) {
                        _this2.set('groupInfo.altRestaurant', { id: altRestaurant.id,
                            name: altRestaurant.name,
                            stand: altRestaurant.stand
                        });
                    }
                    altRestaurant = _this2.get('groupInfo.secondAltRestaurant');
                    if (altRestaurant) {
                        _this2.set('groupInfo.secondAltRestaurant', { id: altRestaurant.id,
                            name: altRestaurant.name,
                            stand: altRestaurant.stand
                        });
                    }
                    _this2.get('addDining')(items, booking, bookingGroup, unallocatedDiningQty);
                });

                return false;
            }
        }
    });
});