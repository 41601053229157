define('debentures-web-public/services/settings', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/utils/range', 'debentures-web-public/models/globals', 'debentures-web-public/models/competition', 'debentures-web-public/models/product'], function (exports, _environment, _range, _globals, _competition, _product) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        globals: null,
        seasons: null,
        competitions: null,

        setup: function setup() {
            var _this = this;

            var forceReload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            // If the setup promise has already been initialised - return the original instance.
            if (!forceReload && this.get('_setupPromise')) {
                return this.get('_setupPromise');
            }

            if (!this.get('session.isAuthenticated')) {
                return Ember.RSVP.resolve();
            }

            var setupPromise = Ember.RSVP.all([this.get('ajax').fetchModel(_globals.default, SERVICES.settings + '/globals').then(function (globals) {
                _this.set('globals', globals);

                var currentYear = new Date().getFullYear();
                _this.set('seasons', (0, _range.range)(globals.initialSeason, currentYear + 1));
            }), this.get('ajax').fetchList(_competition.default, SERVICES.settings + '/competitions').then(function (competitions) {
                return _this.set('competitions', competitions);
            }), this.get('ajax').fetchModel(_product.default, SERVICES.products + '/399a238d-dc74-43c5-a42f-1a6163b36199').then(function (none) {
                return _this.set('none', none);
            })]);

            this.set('_setupPromise', setupPromise);

            return setupPromise;
        }
    });
});