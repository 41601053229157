define('debentures-web-public/pods/my-account/debentures/route', ['exports', 'debentures-web-public/config/environment', 'ember-sf-pageable/mixins/pageable-route', 'debentures-web-public/models/customer', 'debentures-web-public/models/customer-ref', 'debentures-web-public/models/debenture', 'debentures-web-public/models/ewallet-transaction'], function (exports, _environment, _pageableRoute, _customer, _customerRef, _debenture, _ewalletTransaction) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend(_pageableRoute.default, {
        modal: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
                return _this.get('session').setup();
            });
        },
        pageableModel: function pageableModel(params) {
            var customerId = this.get('session').get('currentCustomer').id;

            params['customer'] = customerId;
            return {
                customerLevel: this.get('session').get('currentCustomer').get('level'),
                debentures: this.loadPage('debentures', _debenture.default, SERVICES.debentures + '?customerId=' + customerId),
                customerRef: this.get('ajax').fetchModel(_customerRef.default, SERVICES.bookingRefs + '/customer-ref/' + customerId),
                transactions: this.get('ajax').fetchList(_ewalletTransaction.default, SERVICES.bookingRefs + '/ewallet-transactions/' + customerId)
            };
        },


        actions: {
            remove: function remove(debenture) {
                var _this2 = this;

                this.get('ajax').remove(SERVICES.debentures + '/' + debenture.id).then(function () {
                    return _this2.refresh();
                });
            }
        }
    });
});