define('debentures-web-public/mixins/refreshable-without-scroll-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _activate: Ember.on('activate', function () {
            this.set('bubbleDidTransition', true);
        }),

        refreshWithoutScroll: function refreshWithoutScroll() {
            this.set('bubbleDidTransition', false);
            this.refresh();
        },


        actions: {
            didTransition: function didTransition() {
                if (this.get('bubbleDidTransition') === false) {
                    this.set('bubbleDidTransition', true);
                    return false;
                }

                return true;
            }
        }
    });
});