define('debentures-web-public/models/row', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Row = Ember.Object.extend({
        id: null
    });

    Row.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'row'
        }
    });

    exports.default = Row;
});