define('debentures-web-public/models/fixture-date', ['exports', 'moment', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/debenture-type', 'debentures-web-public/models/fixture-date-settings', 'debentures-web-public/models/fixture-date-ticketing'], function (exports, _moment, _jsonapiModel, _debentureType, _fixtureDateSettings, _fixtureDateTicketing) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureDate = Ember.Object.extend(Ember.Copyable, {
        id: null,
        serial: null,
        date: null,
        time: null,
        fixtureId: null,
        fixtureName: null,
        competitionName: null,
        iconUrl: null,
        completeDining: null,
        completeTicketing: null,
        singleDateFixture: null,
        debentureTypes: null,

        displayName: Ember.computed('fixtureName', 'competitionName', 'serial', 'date', 'singleDateFixture', function () {
            var formattedDate = (0, _moment.default)(this.get('date')).format('dddd MMMM D');
            var name = this.get('fixtureName') + ' (' + this.get('competitionName') + ' - ';
            if (!this.get('singleDateFixture')) {
                name += 'Day ' + this.get('serial') + ', ';
            }
            name += formattedDate + ')';
            return name;
        }),

        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);
            var date = this.get('date');
            ret.set('date', date == null ? null : (0, _moment.default)(date).format('YYYY-MM-DD'));
            return ret;
        },
        fromJSON: function fromJSON() {
            var date = this.get('date');
            this.set('date', Ember.isBlank(date) ? null : (0, _moment.default)(date).toDate());
            this.set('time', Ember.isBlank(date) ? null : (0, _moment.default)(date).tz("Europe/London").format('HH:mm'));
            return this;
        }
    });

    FixtureDate.reopen({
        copy: function copy() {
            return FixtureDate.create(this.getProperties(Object.keys(this)));
        }
    });

    FixtureDate.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-dates',
            relationships: {
                debentureTypes: _debentureType.default,
                settings: _fixtureDateSettings.default,
                ticketing: _fixtureDateTicketing.default
            }
        }
    });

    exports.default = FixtureDate;
});