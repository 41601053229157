define('debentures-web-public/services/basket', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/basket', 'debentures-web-public/models/basket-item', 'debentures-web-public/models/basket-item-list', 'debentures-web-public/models/customer', 'debentures-web-public/models/data-protection-record', 'debentures-web-public/models/checkout', 'debentures-web-public/models/booking', 'debentures-web-public/models/fixture-date'], function (exports, _environment, _basket, _basketItem, _basketItemList, _customer, _dataProtectionRecord, _checkout2, _booking, _fixtureDate) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;


    var BASKET_LIFETIME = 40 * 60; // 40 min
    var DEFAULT_COUNTRY = 'GB';
    var DEFAULT_CURRENCY = 'GBP';

    exports.default = Ember.Service.extend(Ember.Evented, {
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        data: null,
        event: null,

        init: function init() {
            this._super.apply(this, arguments);

            return this.setup();
        },
        setup: function setup() {
            var _this = this;

            var forceReload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            this.flushBasketIfExpired();

            // Make sure normally it's executed only once on a page.
            if (!forceReload && this.get('_setupPromise')) {
                return this.get('_setupPromise');
            }

            var eventId = localStorage.getItem('eventId');
            var basketId = localStorage.getItem('basketId');

            var setupPromise = this.changeEvent(eventId).then(function () {
                return _this.loadBasketData(basketId);
            }).catch(function () {
                return _this.loadBasketData(basketId);
            });

            this.set('_setupPromise', setupPromise);

            return setupPromise;
        },
        refresh: function refresh() {
            var _this2 = this;

            return this.setup(true).then(function () {
                return _this2.trigger('basketRefreshed');
            });
        },
        changeEvent: function changeEvent(eventId) {
            var _this3 = this;

            if (!Ember.isPresent(eventId) || this.get('event.id') === eventId) {
                return Ember.RSVP.resolve(this.get('event'));
            }

            return this.get('ajax').fetchModel(_fixtureDate.default, SERVICES.fixtures + '/date/' + eventId).then(function (fd) {
                localStorage.setItem('eventId', fd.get('id'));
                _this3.set('event', fd);

                return fd;
            });
        },
        loadBasketData: function loadBasketData(basketId) {
            var _this4 = this;

            return new Ember.RSVP.Promise(function (resolve) {
                if (Ember.isPresent(basketId) && basketId !== 'null') {
                    _this4.get('ajax').fetchModel(_basket.default, SERVICES.baskets + '/baskets/' + basketId).then(function (basket) {
                        var client = _this4.get('session.currentCustomer.client');

                        basket.set('client', client);
                        _this4.set('data', basket);
                        localStorage.setItem('basketId', basket.get('id'));

                        if (_this4.get('data.itemsQty') === 0) {
                            _this4.trigger('basketEmpty');
                        }

                        if (_this4.get('session.isAuthenticated')) {
                            _this4.get('ajax').fetchModel(_customer.default, SERVICES.customers + '/me').then(function (customer) {
                                _this4.set('data.customer', customer);
                                resolve(basket);
                            }).catch(function (error) {
                                _this4.set('data.customer', _this4.get('session.currentCustomer'));
                                return error;
                            });
                        } else {
                            var customer = _customer.default.create({ client: client });
                            customer.set('mainAddress.countryId', DEFAULT_COUNTRY);
                            _this4.set('data.customer', customer);
                            resolve(basket);
                        }
                    }).catch(function (error) {
                        resolve(_this4._initBlankBasket());
                        return error;
                    });
                } else {
                    resolve(_this4._initBlankBasket());
                }
            });
        },
        _initBlankBasket: function _initBlankBasket() {
            var client = this.get('session.currentCustomer.client');
            var blankBasket = _basket.default.create({ client: client });

            localStorage.setItem('basketId', null);
            this.set('data', blankBasket);
            this.trigger('basketNotPersisted');

            return blankBasket;
        },
        persist: function persist() {
            var _this5 = this;

            var currency = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_CURRENCY;

            this.set('data.currency', currency);

            // The basket is already persisted.
            if (Ember.isPresent(this.get('data.id'))) {
                return Ember.RSVP.resolve();
            }

            return this.get('ajax').post(SERVICES.baskets + '/baskets', _basket.default.stringify(this.get('data')), _basket.default).then(function (basket) {
                localStorage.setItem('basketId', basket.get('id'));

                // TODO: Why do refresh if we just received up-to-date basket state from server?
                return _this5.refresh();
            });
        },
        flushBasket: function flushBasket() {
            localStorage.removeItem('basketExp');
            localStorage.removeItem('basketId');
            localStorage.removeItem('eventId');
            return this.refresh();
        },
        flushBasketIfExpired: function flushBasketIfExpired() {
            var exp = parseInt(localStorage.getItem('basketExp'));
            var now = Date.now() / 1000 | 0;

            if (exp && exp < now) {
                // If expired - flush the basket.

                this.flushBasket();
                this.trigger('basketExpired');
            } else {
                // Otherwise - extend basket lifetime.

                var eventId = localStorage.getItem('eventId');
                var basketId = localStorage.getItem('basketId');

                if (Ember.isPresent(eventId) || Ember.isPresent(basketId)) {
                    localStorage.setItem('basketExp', now + BASKET_LIFETIME);
                }
            }
        },
        empty: function empty() {
            var _this6 = this;

            this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/clear').then(function () {
                _this6.trigger('basketCleared');

                _this6.set('data.bookingGroups', []);
                _this6.set('data.total', null);
                _this6.flushBasket();
            });
        },
        flushItems: function flushItems(itemList) {
            var _this7 = this;

            var itemsData = _basketItemList.default.stringify(itemList);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function (basket) {
                basket.set('customer', _this7.get('data.customer'));
                _this7.set('data', basket);

                localStorage.setItem('basketId', basket.get('id'));
            });
        },
        updateItems: function updateItems(items) {
            var _this8 = this;

            if (!items || items.length === 0) {
                return Ember.RSVP.resolve();
            }

            var itemsData = _basketItemList.default.stringify(_basketItemList.default.create({
                items: items.map(function (i) {
                    return JSON.parse(_basketItem.default.stringify(i));
                })
            }));

            return this.get('ajax').patch(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this8.refresh();
            });
        },
        replaceItem: function replaceItem(oldItem, newItem) {
            var _this9 = this;

            var list = _basketItemList.default.create({
                items: [JSON.parse(_basketItem.default.stringify(newItem))]
            });
            var itemsData = _basketItemList.default.stringify(list);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this9.removeItem(oldItem);
            });
        },
        replaceItems: function replaceItems(oldItems, newItems) {
            var _this10 = this;

            var list = _basketItemList.default.create({
                items: newItems.map(function (bi) {
                    return JSON.parse(_basketItem.default.stringify(bi));
                })
            });
            var itemsData = _basketItemList.default.stringify(list);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this10.removeItems(oldItems.map(function (bi) {
                    return bi.get('id');
                }));
            });
        },
        removeItem: function removeItem(item) {
            var _this11 = this;

            return this.get('ajax').remove(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products/' + item.id).then(function () {
                return _this11.refresh();
            });
        },
        removeItems: function removeItems(ids) {
            var _this12 = this;

            if (!ids || ids.length === 0) {
                return Ember.RSVP.resolve();
            }

            var itemsData = _basketItemList.default.stringify(_basketItemList.default.create({ items: ids }));

            return this.get('ajax').remove(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this12.refresh();
            });
        },
        createBookingItem: function createBookingItem(bookingGroup, product) {
            var groupMeta = bookingGroup.get('groupMeta');
            var meta = Ember.assign({ group: groupMeta }, product.get('meta'), {
                iconUrl: product.get('iconUrl')
            });

            return _basketItem.default.create({
                status: 'HARD_PENCIL',
                devastate: false,
                event: groupMeta.eventInfo.id,
                bookingGroup: bookingGroup.bookingGroup,
                product: product.get('id'),
                productType: product.get('productType'),
                productGroup: product.get('productGroup'),
                quantity: product.get('_quantity'),
                customization: product.get('_customization'),
                deliveryOption: product.get('deliveryOption'),
                meta: meta
            });
        },
        addProducts: function addProducts(products, additionalMeta) {
            if (!products || products.length === 0) {
                return Ember.RSVP.resolve();
            }

            var list = _basketItemList.default.create({ items: [] });

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = products[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var product = _step.value;

                    if (product.get('_quantity') > 0) {
                        var currentGroupInfo = this.getNewBookingGroupInfo();

                        var meta = Ember.assign({}, currentGroupInfo.meta, product.get('meta'), additionalMeta, {
                            iconUrl: product.get('iconUrl')
                        });

                        var basketItem = _basketItem.default.create({
                            event: this.get('event'),
                            bookingGroup: currentGroupInfo.bookingGroup,
                            product: product,
                            productType: product.get('productType'),
                            productGroup: product.get('productGroup'),
                            quantity: product.get('_quantity'),
                            customization: product.get('_customization'),
                            deliveryOption: product.get('deliveryOption'),
                            meta: meta
                        });

                        list.get('items').pushObject(JSON.parse(_basketItem.default.stringify(basketItem)));
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return this.flushItems(list);
        },
        replaceProduct: function replaceProduct(item, product) {
            var newItem = Ember.copy(item, true);

            newItem.setProperties({
                id: null,
                product: product,
                productType: product.productType,
                productGroup: product.productGroup,
                deliveryOption: product.deliveryOption
            });
            newItem.set('meta', Ember.assign({}, newItem.get('meta'), {
                productType: product.productType,
                iconUrl: product.iconUrl
            }));

            return this.replaceItem(item, newItem);
        },

        /*
        addExtraProducts(products, bookingGroup, additionalMeta) {
            if (products.length > 0) {
                bookingGroup = this.getBookingGroupAsObject(bookingGroup);
                const list = BasketItemList.create({items: []});
                 for (let product of products) {
                    if (product.get('qty') > 0) {
                        const meta = assign({}, bookingGroup.meta, product.get('meta'), additionalMeta, {
                            productType:  product.productType,
                            iconUrl:      product.iconUrl
                        });
                         const basketItem = BasketItem.create({
                            bookingGroup:   bookingGroup.bookingGroup,
                            product:        product,
                            productType:    product.productType,
                            productGroup:   product.productGroup,
                            quantity:       product.qty,
                            deliveryOption: product.deliveryOption,
                            meta:           meta
                        });
                        list.get('items').pushObject(JSON.parse(BasketItem.stringify(basketItem)));
                    }
                }
                 return this.flushItems(list);
            }
             return this.flushItems(BasketItemList.create({items: []}));
        },
        */

        getNewBookingGroupInfo: function getNewBookingGroupInfo() {
            var event = this.get('event');
            if (!Ember.isPresent(event)) {
                throw new Ember.Error('Event is not selected');
            }

            // Use existing event booking group if one exists.
            var bookingGroups = this.get('data.bookingGroups');
            var existingGroup = bookingGroups.findBy('bookingGroup', event.get('id'));
            if (existingGroup) {
                return {
                    bookingGroup: existingGroup.get('bookingGroup'),
                    meta: {
                        group: existingGroup.get('groupMeta')
                    }
                };
            }

            // Otherwise create a new booking group.
            var eventInfo = {
                id: event.get('id'),
                date: event.get('date'),
                serial: event.get('serial'),
                name: event.get('fixtureName'),
                competition: event.get('competitionName'),
                displayName: event.get('displayName')
            };
            var maxGroupIndex = bookingGroups.reduce(function (current, bg) {
                return Math.max(current, bg.get('groupMeta.index'));
            }, 0);

            return {
                bookingGroup: eventInfo.id,
                meta: {
                    group: {
                        id: eventInfo.id,
                        index: maxGroupIndex + 1,
                        eventInfo: eventInfo
                    }
                }
            };
        },

        /*
        getBookingGroupAsObject(bookingGroupStr) {
            return {
                bookingGroup: bookingGroupStr,
                meta:         JSON.parse(bookingGroupStr)
            };
        },
        */

        acceptTerms: function acceptTerms(termsAccepted) {
            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/termsAccepted/' + termsAccepted);
        },
        updateCustomerOptin: function updateCustomerOptin(customerId, optin) {
            var _this13 = this;

            var customer = _customer.default.create({
                id: customerId,
                optin: optin
            });

            return this.get('ajax').patch(SERVICES.customers + '/customers/' + customer.id, _customer.default.stringify(customer), _customer.default).then(function (customer) {
                var dataProtection = _dataProtectionRecord.default.stringify(_dataProtectionRecord.default.create({
                    clientId: _this13.get('session.currentCustomer.client.id'),
                    customer: customer,
                    type: 'OPTIN',
                    source: 'ADMIN_BOOKER',
                    value: customer.optin,
                    changeRequesterType: 'AGENT'
                }));

                return _this13.get('ajax').post(SERVICES.customers + '/data-protection', dataProtection);
            });
        },
        attachCustomer: function attachCustomer(customer) {
            var _this14 = this;

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/customer/' + customer.get('id')).then(function () {
                return _this14.refresh();
            });
        },
        detachCustomer: function detachCustomer() {
            var _this15 = this;

            return this.get('ajax').remove(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/customer').then(function () {
                return _this15.refresh();
            });
        },
        updateInfo: function updateInfo(info) {
            if (Ember.isPresent(info)) {
                this.set('data.info', info);
            }

            var basketData = _basket.default.stringify(this.get('data'));

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/info', basketData, _basket.default);
        },
        addDiscount: function addDiscount(discountInfo) {
            var result = this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/discount', discountInfo, _basket.default);
            console.log(JSON.stringify(result));
            return result;
        },
        checkout: function checkout(_checkout) {
            var checkoutData = _checkout2.default.stringify(_checkout);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/checkout?newVersion=true', checkoutData, _booking.default);
        }
    });
});