define('debentures-web-public/models/template-source', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var TemplateSource = Ember.Object.extend({
        id: null,
        name: null,
        subject: null,
        parts: null
    });

    TemplateSource.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'template-sources'
        }
    });

    exports.default = TemplateSource;
});