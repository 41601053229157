define('debentures-web-public/models/basket-item', ['exports', 'big.js', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/utils/range', 'debentures-web-public/models/product', 'debentures-web-public/models/basket-item-customization', 'debentures-web-public/models/fixture-date'], function (exports, _big, _jsonapiModel, _range, _product, _basketItemCustomization, _fixtureDate) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var STATUS_CANCELLED = 'CANCELLED';

    var ALLOCATION_CONFIRMED = 'Confirmed';
    var ALLOCATION_REJECTED = 'Rejected';

    var GROUP_TICKETS = 'Tickets';
    var GROUP_DINING = 'Dining';

    var BasketItem = Ember.Object.extend(Ember.Copyable, {
        id: null,
        created: null,
        name: null,
        product: null,
        customization: null,
        quantity: null,
        quantityToCancel: null,
        bookingGroup: null,
        unitPrice: null,
        price: null,
        total: null,
        balance: null,
        discount: null,
        promotionDiscount: null,
        event: null,
        meta: null,
        productType: null,
        productGroup: null,
        deliveryOption: null,
        cancelled: null,
        status: null,
        itemsGroup: null,

        isCancelled: Ember.computed('status', function () {
            return STATUS_CANCELLED === this.get('status');
        }),

        isTicket: Ember.computed('productGroup', function () {
            return GROUP_TICKETS === this.get('productGroup');
        }),
        isDining: Ember.computed('productGroup', function () {
            return GROUP_DINING === this.get('productGroup');
        }),
        isNone: Ember.computed('productType', function () {
            return 'NONE' === this.get('productType');
        }),
        isAllocationConfirmed: Ember.computed('customization', function () {
            return ALLOCATION_CONFIRMED === this.get('customization').get('allocationStatus');
        }),
        allocationStatusClass: Ember.computed('customization', function () {
            var status = this.get('customization').get('allocationStatus');
            if (status === ALLOCATION_CONFIRMED || status === ALLOCATION_REJECTED) {
                if (status === ALLOCATION_CONFIRMED) {
                    return "success";
                } else if (status === ALLOCATION_REJECTED) {
                    return "danger";
                } else {
                    return "warning";
                }
            } else {
                return "";
            }
        }),
        allocationIconClass: Ember.computed('customization', function () {
            var status = this.get('customization').get('allocationStatus');
            if (status === ALLOCATION_CONFIRMED || status === ALLOCATION_REJECTED) {
                if (status === ALLOCATION_CONFIRMED) {
                    return "fa fa-check";
                } else if (status === ALLOCATION_REJECTED) {
                    return "fa fa-times";
                }
            }
            return "";
        }),

        isCancellable: Ember.computed('status', function () {
            return this.get('status') === 'AUTHORISED';
        }),

        isAwaitingAllocation: Ember.computed('customization', function () {
            return this.get('customization') != null && this.get('customization.allocated') != null && this.get('customization.allocated') && !this.get('isCancelled') && this.get('customization.allocationStatus') == null;
        }),

        quantitiesToCancel: Ember.computed('quantity', function () {
            return (0, _range.range)(1, this.get('quantity'));
        }),

        unitBalance: Ember.computed('balance', 'quantity', function () {
            var balance = this.get('balance');
            var quantity = this.get('quantity');

            if (Ember.isBlank(balance) || Ember.isBlank(quantity)) {
                return 0;
            }

            return Number(new _big.default(balance).div(quantity));
        }),

        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);

            if (Ember.isBlank(this.get('customization'))) {
                ret.set('customization', undefined);
            }
            if (Ember.isBlank(this.get('deliveryOption'))) {
                ret.set('deliveryOption', undefined);
            }

            return ret;
        }
    });

    BasketItem.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'basket-items',
            relationships: {
                product: _product.default,
                event: _fixtureDate.default
            }
        },
        convert: function convert(json, group) {
            return BasketItem.create({
                id: json.id,
                created: Ember.isBlank(json.created) ? null : new Date(json.created),
                name: json.name,
                product: json.product,
                customization: Ember.isPresent(json.customization) ? _basketItemCustomization.default.convert(json.customization) : json.customization,
                quantity: json.quantity,
                bookingGroup: group,
                unitPrice: json.unitPrice,
                price: json.price,
                total: json.total,
                balance: json.balance || 0,
                event: json.event,
                meta: json.meta,
                productType: json.productType,
                productGroup: json.productGroup,
                deliveryOption: json.deliveryOption,
                cancelled: json.cancelled,
                status: json.status,
                itemsGroup: json.itemsGroup
            });
        },
        convertArray: function convertArray(jsonArray, group) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item, group);
            });
        }
    });

    BasketItem.reopen({
        copy: function copy() {
            return BasketItem.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = BasketItem;
});