define('debentures-web-public/pods/payment-callback/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/payment-info', 'debentures-web-public/models/basket'], function (exports, _environment, _paymentInfo, _basket) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        basket: Ember.inject.service(),

        queryParams: {
            merchantSig: { refreshModel: true },
            pspReference: { refreshModel: true },
            skinCode: { refreshModel: true },
            authResult: { refreshModel: true },
            paymentMethod: { refreshModel: true },
            shopperLocale: { refreshModel: true },
            merchantReference: { refreshModel: true }
        },

        model: function model(params, transition) {
            var _this = this;

            this.setPaymentError(null);

            this.handleTransactionResponse(transition, params, function (transactionJSON) {
                var transaction = transactionJSON.attributes;
                console.log(transaction.status);

                if (transaction.status === 'AUTHORISED' || transaction.status === 'PENDING') {
                    _this.redirectToConfirm(transaction.orderId);
                } else if (transaction.status === 'CANCELLED') {
                    _this.redirectToPaymentCentre(transition, transaction.clientRedirectUrl);
                } else {
                    console.log("Strange status: " + transaction.status);
                    _this.setPaymentError(transaction.error);
                    _this.redirectToPaymentCentre(transition, transaction.clientRedirectUrl);
                }
            });
        },
        handleTransactionResponse: function handleTransactionResponse(transition, params, callback) {
            var _this2 = this;

            if (Ember.isEmpty(params.merchantReference) || Ember.isEmpty(params.authResult) || Ember.isEmpty(params.pspReference)) {
                console.log("Bad request");
                this.redirectToPaymentCentre(transition);
                return;
            }

            this.get('ajax').request(SERVICES.payments + '/barclays/smartpay/transaction-responses', params).then(function (responseData) {
                // Transaction has already been processed or not found.
                if (Ember.isEmpty(responseData)) {
                    // TODO: What to do if transaction has already been processed or not found?
                    _this2.redirectToPaymentCentre(transition);
                    return;
                }

                callback(responseData);
            }).catch(function () {
                _this2.setPaymentError('Unknown payment gateway error');
                _this2.redirectToPaymentCentre(transition);
            });
        },
        redirectToConfirm: function redirectToConfirm(bookingId) {
            this.transitionTo('bookings.view', bookingId);
        },
        setPaymentError: function setPaymentError(error) {
            if (Ember.isNone(error)) {
                localStorage.removeItem('paymentError');
            } else {
                localStorage.setItem('paymentError', error);
            }
        },
        redirectToPaymentCentre: function redirectToPaymentCentre(transition, tag) {
            transition.abort();
            var bookingId = localStorage.getItem('bookingId');
            var ewalletDebit = localStorage.getItem('ewalletDebit');

            if (ewalletDebit != null) {
                localStorage.removeItem('ewalletDebit');
                this.redirectToConfirm(bookingId);
            } else if ("Additional" === tag) {
                this.additionalPaymentRedirect(bookingId);
            } else {
                this.initialPaymentRedirect(bookingId, bookingId);
            }
        },
        initialPaymentRedirect: function initialPaymentRedirect(bookingId) {
            var _this3 = this;

            var paymentInfoData = _paymentInfo.default.stringify(_paymentInfo.default.create({ paymentMethod: 'CARD' }));

            this.get('ajax').post(SERVICES.baskets + '/bookings/' + bookingId + '/cancel', paymentInfoData, _basket.default).then(function () {
                localStorage.removeItem('bookingId');

                // FIXME error handling
                //localStorage.setItem('eventId', localStorage.getItem('eventId_arc'));
                //localStorage.setItem('basketExp', localStorage.getItem('basketExp_arc'));
                //localStorage.setItem('basketId', localStorage.getItem('basketId_arc'));
                //localStorage.removeItem('basketExp_arc');
                //localStorage.removeItem('basketId_arc');
                //localStorage.removeItem('eventId_arc');

                _this3.get('basket').refresh().then(function () {
                    _this3.replaceWith('book.payment-centre');
                });
                _this3.get('basket').flushBasket();
            }).catch(function () {
                localStorage.removeItem('bookingId');
                _this3.replaceWith('book.payment-centre');
            });
        },

        doRedirectToAdditional: function doRedirectToAdditional(bookingId) {
            localStorage.removeItem('bookingId');
            this.replaceWith('bookings.view.add-payment-modal-wrapper', bookingId);
        },
        additionalPaymentRedirect: function additionalPaymentRedirect(bookingId, checkoutId) {
            var _this4 = this;

            if (checkoutId) {
                this.get('ajax').post(SERVICES.bookings + '/bookings/' + bookingId + '/checkouts/' + checkoutId + '/cancel').then(function () {
                    _this4.doRedirectToAdditional(bookingId);
                }).catch(function () {
                    _this4.doRedirectToAdditional(bookingId);
                });
            } else {
                this.doRedirectToAdditional(bookingId);
            }
        }
    });
});