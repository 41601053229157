define('debentures-web-public/pods/book/select-products/booking-wizard/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        flashMessages: Ember.inject.service(),

        ticketsQuantity: Ember.computed('options.tickets.@each.selected', 'bookingGroup.groupInfo.additionalTickets', function () {
            var result = this.get('bookingGroup.groupInfo.additionalTickets') || 0;

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('options.tickets')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var to = _step.value;

                    if (to.get('selected')) {
                        result++;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return result;
        }),
        options: Ember.computed('bookingGroup.items.[]', function () {
            var ticketOptions = [];
            var diningOptions = [];

            if (this.get('bookingGroup')) {
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = this.get('bookingGroup.items')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var bi = _step2.value;

                        if (bi.get('isTicket')) {
                            ticketOptions.push(bi);
                        } else if (bi.get('isDining')) {
                            diningOptions.push(bi);
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            }

            return {
                tickets: ticketOptions,
                dining: diningOptions
            };
        }),

        isNoSeatSelected: function isNoSeatSelected() {
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = this.get('bookingGroup.items')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var bi = _step3.value;

                    if (bi.get('selected') && bi.get('isTicket')) {
                        return false;
                    }
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                        _iterator3.return();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            return true;
        },


        actions: {
            updateGroupInBasket: function updateGroupInBasket() {
                var _this = this;

                if (this.get('isUpdatingBasket')) {
                    return;
                }

                if (this.isNoSeatSelected()) {
                    this.set('errors', { noSeatSelected: true });
                    return;
                }

                this.setProperties({
                    isUpdatingBasket: true,
                    errors: null
                });

                this.get('updateGroupInBasket')().catch(function (errors) {
                    return _this.set('errors', errors);
                }).finally(function () {
                    return _this.set('isUpdatingBasket', false);
                });
            }
        }
    });
});