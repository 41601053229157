define('debentures-web-public/models/stand', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Stand = Ember.Object.extend(Ember.Copyable, {
        id: null,
        name: null
    });

    Stand.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'stands'
        }
    });

    Stand.reopen({
        copy: function copy() {
            return Stand.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = Stand;
});