define('debentures-web-public/models/user', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/client'], function (exports, _jsonapiModel, _client) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    // import TemporalMedia from './temporal-media';

    var User = Ember.Object.extend({
        id: null,
        client: null,
        firstName: null,
        lastName: null,
        email: null,
        telephone: null,
        roles: null,
        status: null,
        avatarUrl: null,

        // Use only to post avatar to the server.
        avatar: null,

        // Used for user activation & password change.
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,

        fullName: Ember.computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }),

        isInactive: Ember.computed('status', function () {
            return this.get('status') === 'INACTIVE';
        }),
        isActive: Ember.computed('status', function () {
            return this.get('status') === 'ACTIVE';
        }),
        isDisabled: Ember.computed('status', function () {
            return this.get('status') === 'DISABLED';
        }),

        statusClass: Ember.computed('status', function () {
            switch (this.get('status')) {
                case 'INACTIVE':
                    return 'red';
                case 'ACTIVE':
                    return 'green';
                default:
                    return '';
            }
        }),

        tableStatusClass: Ember.computed('status', function () {
            switch (this.get('status')) {
                case 'INACTIVE':
                    return 'table-status-inactive';
                case 'ACTIVE':
                    return 'table-status-active';
                default:
                    return 'table-status-deactivated';
            }
        })

    });

    User.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'users',
            relationships: {
                client: _client.default
                // avatar: TemporalMedia,
            }
        }
    });

    exports.default = User;
});