define('debentures-web-public/pods/book/select-products/dining-options/component', ['exports', 'debentures-web-public/utils/range'], function (exports, _range) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        quantityOptions: Ember.computed('ticketsQuantity', function () {
            return (0, _range.range)(1, this.get('ticketsQuantity'));
        }),

        restaurants: Ember.computed('options.[]', function () {
            this.get('options').sort(function (i1, i2) {
                var sitting1 = i1.get('product.meta.firstSitting');
                var sitting2 = i2.get('product.meta.firstSitting');

                if (sitting1 == null && sitting2 != null || sitting1 > sitting2) {
                    return 1;
                } else if (sitting2 == null && sitting1 != null || sitting1 < sitting2) {
                    return -1;
                } else {
                    return i1.get('product.price') - i2.get('product.price');
                }
            });

            var restaurantsMap = this.get('options').reduce(function (accum, opt) {
                var rest = opt.get('product.meta.restaurant');

                if (accum[rest.id]) {
                    accum[rest.id].options.push(opt);
                } else {
                    accum[rest.id] = Ember.assign({}, rest, {
                        options: [opt]
                    });
                }

                return accum;
            }, {});

            return Object.values(restaurantsMap);
        }),
        isSingleRestaurant: Ember.computed('restaurants.[]', function () {
            return this.get('restaurants').length === 1;
        }),
        altRestaurants: Ember.computed('restaurants.[]', 'selectedRestaurant', function () {
            var selected = this.get('selectedRestaurant');

            return !selected ? [] : this.get('restaurants').filter(function (r) {
                return r.id !== selected.id;
            });
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            if (this.get('groupInfo.noDiningRequired')) {
                this.set('isShowDiningOptions', this.get('groupInfo.noDiningRequired'));
                return;
            }

            if (this.get('isSingleRestaurant')) {
                this.set('selectedRestaurant', this.get('restaurants')[0]);
            } else {
                var newSelectedRestaurant = null;

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = this.get('restaurants')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var rest = _step.value;

                        if (newSelectedRestaurant) {
                            break;
                        }

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = rest.options[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var item = _step2.value;

                                if (item.get('selected')) {
                                    newSelectedRestaurant = rest;
                                    break;
                                }
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                if (newSelectedRestaurant) {
                    this.setProperties({
                        selectedRestaurant: newSelectedRestaurant,
                        isShowDiningOptions: true
                    });
                }
            }

            if (this.get('groupInfo.altRestaurant')) {
                var altRestaurant = this.get('groupInfo.altRestaurant');

                this.set('groupInfo.altRestaurant', this.get('altRestaurants').findBy('id', altRestaurant.id));
            }
        },
        setItemQuantity: function setItemQuantity(item, quantity) {
            item.set('selected', quantity > 0);
            item.set('quantity', quantity);
        },


        actions: {
            showDiningOptions: function showDiningOptions() {
                this.set('isShowDiningOptions', true);
            },
            toggleNoDiningRequired: function toggleNoDiningRequired() {
                this.toggleProperty('groupInfo.noDiningRequired');

                this.set('isShowDiningOptions', this.get('groupInfo.noDiningRequired'));
            },
            changeRestaurant: function changeRestaurant(rest) {
                this.set('selectedRestaurant', rest);

                // Reset quantity selection on all other restaurant packages.
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = this.get('restaurants')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var r = _step3.value;

                        if (!rest || rest.id !== r.id) {
                            var _iteratorNormalCompletion4 = true;
                            var _didIteratorError4 = false;
                            var _iteratorError4 = undefined;

                            try {
                                for (var _iterator4 = r.options[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                                    var item = _step4.value;

                                    this.setItemQuantity(item, 0);
                                }
                            } catch (err) {
                                _didIteratorError4 = true;
                                _iteratorError4 = err;
                            } finally {
                                try {
                                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                                        _iterator4.return();
                                    }
                                } finally {
                                    if (_didIteratorError4) {
                                        throw _iteratorError4;
                                    }
                                }
                            }
                        }
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }
            },
            changeQuantity: function changeQuantity(item, quantity) {
                this.setItemQuantity(item, quantity);
            }
        }
    });
});