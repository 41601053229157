define('debentures-web-public/pods/fixtures/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        beforeModel: function beforeModel() {
            this.transitionTo('book.quick-booking');
        }
    });
});