define('debentures-web-public/models/booking', ['exports', 'big.js', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/utils/collectors', 'debentures-web-public/models/customer', 'debentures-web-public/models/booking-group', 'debentures-web-public/models/client', 'debentures-web-public/models/created-by'], function (exports, _big, _jsonapiModel, _collectors, _customer, _bookingGroup, _client, _createdBy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var STATUS_CANCELLED = 'CANCELLED';

    var Booking = Ember.Object.extend({
        id: null,
        reference: null,
        created: null,
        bookingGroups: null,
        discountAmount: null,
        discountPercent: null,
        promotionAmount: null,
        promotionCode: null,
        channel: null,
        currency: null,
        amount: null,
        balance: null,
        status: null,
        accessCode: null,
        customer: null,
        client: null,
        info: null,
        createdBy: null,

        sortedGroups: Ember.computed('bookingGroups.@each.index', function () {
            return this.get('bookingGroups').sortBy('index');
        }),
        groupItems: Ember.computed('bookingGroups.@each.index', function () {
            return (0, _collectors.flatMap)(this.get('bookingGroups'), function (bg) {
                return bg.get('items');
            });
        }),
        amountDue: Ember.computed('amount', 'balance', function () {
            return Math.max(0, new _big.default(this.get('amount')).minus(this.get('balance') || 0));
        }),
        isFullyPaid: Ember.computed('amountDue', function () {
            return this.get('amountDue') === 0;
        }),
        isAwaitingAllocation: Ember.computed('bookingGroups.@each', function () {
            return this.get('bookingGroups').some(function (bg) {
                return bg.isAwaitingAllocation;
            });
        }),
        hasPayableItems: Ember.computed('bookingGroups.@each', function () {
            return this.get('bookingGroups').some(function (bg) {
                return bg.isAdditionalPaymentPhase || bg.isDiningConfirmationPhase;
            });
        }),
        statusClass: Ember.computed('status', function () {
            return this.get('isFullyPaid') ? 'success' : 'warning';
        }),

        fixtures: Ember.computed('bookingGroups.[]', function () {
            return this.get('bookingGroups').length;
        }),
        paymentStatus: Ember.computed('', function () {
            if (STATUS_CANCELLED === this.get('status')) {
                return 'CANCELLED';
            }
            if (this.get('amountDue') === 0) {
                return 'PAID';
            }
            if (this.get('amountDue') !== 0 && this.get('balance') > 0) {
                return 'PARTIALLY_PAID';
            }
            return 'PAYMENT_DUE';
        }),
        paymentStatusClass: Ember.computed('status', function () {
            switch (this.get('paymentStatus')) {
                case 'PAYMENT_DUE':
                    return 'warning';
                case 'PARTIALLY_PAID':
                    return 'info';
                case 'PAID':
                    return 'success';
                case 'CANCELLED':
                    return 'default';
                default:
                    return '';
            }
        }),

        fromJSON: function fromJSON() {
            var groupsInfo = this.get('info.groupsInfo');

            var bookingGroups = this.get('bookingGroups');
            if (Ember.isPresent(bookingGroups)) {
                this.set('bookingGroups', _bookingGroup.default.convertArray(bookingGroups, groupsInfo));
            }

            this.set('createdBy', _createdBy.default.create(this.get('createdBy')));

            return this;
        }
    });

    Booking.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'bookings',
            relationships: {
                customer: _customer.default,
                client: _client.default
            }
        }
    });

    exports.default = Booking;
});