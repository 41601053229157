define('debentures-web-public/breakpoints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    mobile: '(max-width: 800px)',
    desktop: '(min-width: 801px)'
  };
});