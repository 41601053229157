define('debentures-web-public/models/fixture-date-pricepoint', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/fixture-date-ticketing'], function (exports, _jsonapiModel, _fixtureDateTicketing) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixturePricePoint = Ember.Object.extend(Ember.Copyable, {
        id: null,

        inventory: null,
        onSale: null,
        price: null,
        productName: null,
        stand: null,
        ticketing: null,

        //TODO: subtract booked
        ticketBoxClass: Ember.computed('inventory', function () {
            var placesLeft = this.get('inventory');
            return placesLeft > 10 ? 'available-box' : placesLeft > 0 ? 'running-out-box' : 'no-ticket-box';
        }),

        statusText: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'On Sale' : 'Off Sale';
        }),

        statusClass: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'is-active' : 'is-disabled';
        }),

        statusBarClass: Ember.computed('onSale', function () {
            return this.get('onSale') ? 'green' : 'disabled';
        }),

        toJSON: function toJSON() {
            var result = Ember.copy(this, true);

            result.set('inventory', Ember.isBlank(this.get('inventory')) ? null : parseInt(this.get('inventory')));
            result.set('price', Ember.isBlank(this.get('price')) ? null : parseFloat(this.get('price')));
            return result;
        }
    });

    FixturePricePoint.reopen({
        copy: function copy() {
            return FixturePricePoint.create(this.getProperties(Object.keys(this)));
        }
    });

    FixturePricePoint.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-price-points',
            relationships: {
                ticketing: _fixtureDateTicketing.default
            }
        }
    });

    exports.default = FixturePricePoint;
});