define('debentures-web-public/helpers/percent', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        compute: function compute(params) {
            return params[0] * 100 + '%';
        }
    });
});