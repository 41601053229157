define('debentures-web-public/pods/my-account/addresses/index/route', ['exports', 'debentures-web-public/config/environment', 'ember-sf-pageable/mixins/pageable-route', 'debentures-web-public/models/customer-address'], function (exports, _environment, _pageableRoute, _customerAddress) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend(_pageableRoute.default, {
        session: Ember.inject.service(),

        pageableModel: function pageableModel() {
            return {
                addresses: this.loadPage('addresses', _customerAddress.default, SERVICES.customers + '/customers/' + this.get('session').get('currentCustomer').id + '/addresses')
            };
        }
    });
});