define('debentures-web-public/session-stores/application', ['exports', 'ember-simple-auth/session-stores/adaptive'], function (exports, _adaptive) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adaptive.default.extend({
        localStorageKey: '_debentures_wslf1OTlLY5',
        cookieName: '_debentures_wslf1OTlLY5'
    });
});