define('debentures-web-public/components/confirmation-modal', ['exports', 'ember-sf-modal/components/confirmation-modal'], function (exports, _confirmationModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _confirmationModal.default;
    }
  });
});