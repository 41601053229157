define('debentures-web-public/pods/book/view-basket/basket-details/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        basket: Ember.inject.service(),
        flashMessages: Ember.inject.service(),

        actions: {
            removeGroup: function removeGroup(group) {
                var _this = this;

                var ids = group.items.map(function (i) {
                    return i.id;
                });

                this.get('basket').removeItems(ids).then(function () {
                    if (_this.get('basket.data.hasProducts')) {
                        delete _this.get('basket.data.info.groupsInfo')[group.bookingGroup];
                        _this.get('basket').updateInfo();
                    }
                });
            },
            updateRequests: function updateRequests() {
                var _this2 = this;

                var basketInfo = this.get('basket.data.info');
                return this.get('basket').updateInfo(basketInfo).then(function () {
                    return _this2.get('flashMessages').success('Updated');
                });
            },
            toggleBubble: function toggleBubble(groupInfo, value) {
                Ember.set(groupInfo, 'socialBubble', value);
            },
            toggleLunchPack: function toggleLunchPack(groupInfo, value) {
                Ember.set(groupInfo, 'lunchPack', value);
            }
        }
    });
});