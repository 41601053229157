define('debentures-web-public/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-sf-pageable/mixins/searchable-route'], function (exports, _applicationRouteMixin, _searchableRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, _searchableRoute.default, {
        settings: Ember.inject.service(),
        intl: Ember.inject.service(),

        beforeModel: function beforeModel() {
            var _this = this;

            // Wrapped into RSVP.resolve() to make sure the result of _super() is a promise.
            var superPromise = Ember.RSVP.resolve(this._super.apply(this, arguments));

            return superPromise.then(function () {
                return _this.intl.setLocale(['en']);
            });
        },
        model: function model() {
            return Ember.RSVP.all([this._super.apply(this, arguments), this._setupSessionServices()]);
        },
        sessionAuthenticated: function sessionAuthenticated() {
            this._super.apply(this, arguments);

            this._setupSessionServices();
        },
        _setupSessionServices: function _setupSessionServices() {
            return Ember.RSVP.all([this.get('session').setup(), this.get('settings').setup()]);
        },


        actions: {
            didTransition: function didTransition() {
                //this.transitionTo('holding-page');

                window.scrollTo(0, 0);
                if (this.get('session.isAuthenticated')) {
                    Ember.run.scheduleOnce('afterRender', this, function () {
                        Ember.$('#responsive-table').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table2').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table3').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table4').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table5').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table6').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table7').stacktable({ myClass: 'table' });
                        Ember.$('#responsive-table8').stacktable({ myClass: 'table' });
                    });
                }
            },
            searchFixtures: function searchFixtures(searchParams) {
                this.searchTransition('fixtures', searchParams);
            }
        }
    });
});