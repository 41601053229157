define('debentures-web-public/pods/book/quick-booking/fixtures-list/fixture-row/component', ['exports', 'debentures-web-public/utils/range'], function (exports, _range) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        flashMessages: Ember.inject.service(),
        settings: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            var maxAdditionalSeats = this.get('settings.globals.maxAdditionalSeats');
            if (this.get('fixtureDate.restrictedSeating')) {
                maxAdditionalSeats = this.get('debentures') != null ? this.get('debentures').length : 0;
            }
            var possessedTypes = new Set();
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('debentures')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var d = _step.value;

                    possessedTypes.add(d.type.id);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            if (this.get('fixtureDate.debentureTypes')) {
                this.get('fixtureDate').prohibited = true;
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = this.get('fixtureDate.debentureTypes')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var dt = _step2.value;

                        if (possessedTypes.has(dt.id)) {
                            this.get('fixtureDate').prohibited = false;
                            break;
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }
            }

            this.set('additionalQuantities', (0, _range.range)(1, maxAdditionalSeats));
        },


        bookingGroup: Ember.computed('fixtureDate.booking', function () {
            var fixtureDate = this.get('fixtureDate');
            return fixtureDate.get('booking.bookingGroups').findBy('bookingGroup', fixtureDate.get('id'));
        }),
        bookingGroupTicketItems: Ember.computed('bookingGroup.items.[]', function () {
            var bookingGroup = this.get('bookingGroup');

            return !bookingGroup ? [] : bookingGroup.get('items').filter(function (it) {
                return it.get('isTicket') && !it.get('isCancelled');
            });
        }),
        debentureSeatsCount: Ember.computed('bookingGroup', 'bookingGroupTicketItems.[]', function () {
            return this.get('bookingGroupTicketItems').filter(function (it) {
                return !it.get('customization.allocated');
            }).length;
        }),
        additionalSeatsCount: Ember.computed('bookingGroup', 'bookingGroupTicketItems.[]', function () {
            var bookingGroup = this.get('bookingGroup');
            var allocatedCount = this.get('bookingGroupTicketItems').filter(function (it) {
                return it.get('customization.allocated');
            }).length;

            return allocatedCount + bookingGroup.get('groupInfo.additionalTickets');
        }),

        isNoSeatSelected: function isNoSeatSelected(bookingGroup) {
            /*let additionalTickets = bookingGroup.get('groupInfo.additionalTickets') || 0;
            if (additionalTickets > 0) {
                return false;
            }*/
            return bookingGroup.get('items').filter(function (it) {
                return it.get('selected');
            }).length === 0;
        },


        actions: {
            addOrUpdateTickets: function addOrUpdateTickets(fixtureDate) {
                var _this = this;

                if (this.get('isUpdatingBasket')) {
                    return;
                }

                var bookingGroup = fixtureDate.get('bookingGroup');
                if (this.isNoSeatSelected(bookingGroup)) {
                    this.set('errors', {
                        noSeatsSelected: true
                    });
                    return;
                } else {
                    bookingGroup.groupInfo.restrictedSeating = fixtureDate.get('restrictedSeating');
                }

                this.setProperties({
                    isUpdatingBasket: true,
                    errors: null
                });

                this.get('updateGroupInBasket')(fixtureDate.get('id'), bookingGroup).then(function () {
                    return _this.get('flashMessages').success('Quantities updated');
                }).catch(function (errors) {
                    return _this.set('errors', errors);
                }).finally(function () {
                    return _this.set('isUpdatingBasket', false);
                });
            }
        }
    });
});