define('debentures-web-public/services/modal', ['exports', 'ember-sf-modal/services/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modal.default;
    }
  });
});