define('debentures-web-public/models/fixture', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/competition', 'debentures-web-public/models/debenture-type', 'debentures-web-public/models/dining-rights', 'debentures-web-public/models/fixture-date', 'debentures-web-public/models/seating-plan'], function (exports, _jsonapiModel, _competition, _debentureType, _diningRights, _fixtureDate, _seatingPlan) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Fixture = Ember.Object.extend({
        id: null,
        name: null,
        description: null,
        iconUrl: null,
        bannerUrl: null,
        bannerText: null,
        competition: null,
        dates: null,
        diningRights: null,
        seatingPlan: null,

        fromJSON: function fromJSON() {
            this.set('dates', this.get('dates').sortBy("serial"));
            return this;
        }
    });

    Fixture.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixtures',
            relationships: {
                competition: _competition.default,
                debentureTypes: _debentureType.default,
                diningRights: _diningRights.default,
                dates: _fixtureDate.default,
                seatingPlan: _seatingPlan.default
            }
        }
    });

    exports.default = Fixture;
});