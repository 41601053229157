define('debentures-web-public/models/booking-group', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/basket-item'], function (exports, _jsonapiModel, _basketItem) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var PRODUCT_GROUPS_ORDER = ['Tickets', 'Dining'];

    var BookingGroup = Ember.Object.extend({
        bookingGroup: null,
        items: null,
        groupSubtotal: null,
        groupDiscount: null,
        groupPromotionDiscount: null,
        groupTotal: null,
        groupInfo: null,

        groupMeta: Ember.computed('items.[]', function () {
            var items = this.get('items');
            var item0 = items && items[0];

            return item0.get('meta') && item0.get('meta.group');
        }),
        index: Ember.computed('groupMeta.index', function () {
            return this.get('groupMeta.index');
        }),

        sortedItems: Ember.computed('items.[]', function () {
            return this.sortItems(this.get('items'));
        }),

        isAwaitingAllocation: Ember.computed('items.[]', function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('items')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var item = _step.value;

                    if (item.isAwaitingAllocation) {
                        return true;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return false;
        }),

        answersGiven: Ember.computed('groupInfo.lunchPack', 'groupInfo.socialBubble', function () {
            return !this.get('groupInfo.restrictedSeating') || this.get('groupInfo.lunchPack') != null && this.get('groupInfo.socialBubble') != null;
        }),

        sortItems: function sortItems(items) {
            return items.sort(function (i1, i2) {
                if (i1.get('productGroup') === i2.get('productGroup')) {
                    if (i1.get('customization.seat')) {
                        return i1.get('customization.seat.seatNumber') - i2.get('customization.seat.seatNumber');
                    } else if (i1.get('customization.restaurant')) {
                        var restaurantComparison = i1.get('customization.restaurant.name').localeCompare(i2.get('customization.restaurant.name'));
                        if (restaurantComparison == 0) {
                            var sitting1 = i1.get('meta.firstSitting');
                            var sitting2 = i2.get('meta.firstSitting');
                            if (sitting1 == null && sitting2 != null || sitting1 > sitting2) {
                                return 1;
                            } else if (sitting2 == null && sitting1 != null || sitting1 < sitting2) {
                                return -1;
                            } else {
                                return i1.get('price') - i2.get('price');
                            }
                        } else {
                            return restaurantComparison;
                        }
                    }
                }

                return PRODUCT_GROUPS_ORDER.indexOf(i1.get('productGroup')) - PRODUCT_GROUPS_ORDER.indexOf(i2.get('productGroup'));
            });
        },


        groupedSortedItems: Ember.computed('items.[]', function () {
            var groupedItems = this.get('items').reduce(function (result, item) {
                // Skip everything except of tickets.
                if (!item.get('isTicket')) {
                    result[item.get('product')] = item;
                    return result;
                }

                var stand = item.get('customization.seat.stand');
                if (!result[stand]) {
                    result[stand] = item;
                } else {
                    result[stand].set('quantity', result[stand].get('quantity') + item.get('quantity'));
                }
                return result;
            }, {});
            return this.sortItems(Object.values(groupedItems));
        })
    });

    BookingGroup.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'booking-groups'
        },
        convert: function convert(json, groupsInfo) {
            return BookingGroup.create({
                bookingGroup: json.bookingGroup,
                items: _basketItem.default.convertArray(json.items, json.bookingGroup),
                groupSubtotal: json.groupSubtotal,
                groupDiscount: json.groupDiscount,
                groupPromotionDiscount: json.groupPromotionDiscount,
                groupTotal: json.groupTotal,
                groupInfo: groupsInfo[json.bookingGroup]
            });
        },
        convertArray: function convertArray(jsonArray, groupsInfo) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item, groupsInfo);
            });
        }
    });

    exports.default = BookingGroup;
});