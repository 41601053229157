define('debentures-web-public/models/debenture', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/debenture-type', 'debentures-web-public/models/seat', 'debentures-web-public/models/customer'], function (exports, _jsonapiModel, _debentureType, _seat, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Debenture = Ember.Object.extend(Ember.Copyable, {
        id: null,
        start: null,
        duration: null,
        certificateNumber: null,
        type: null,
        seat: null,
        customer: null,

        period: Ember.computed('start', 'duration', function () {
            return this.get('start') + ' - ' + this.get('end');
        }),

        toJSON: function toJSON() {
            var ret = Ember.copy(this, true);
            ret.set('start', parseInt(this.get('start')));
            ret.set('duration', parseInt(this.get('duration')));
            return ret;
        }
    });

    Debenture.reopen({
        copy: function copy() {
            return Debenture.create(this.getProperties(Object.keys(this)));
        }
    });

    Debenture.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'debentures',
            relationships: {
                type: _debentureType.default,
                seat: _seat.default,
                customer: _customer.default
            }
        }
    });

    exports.default = Debenture;
});