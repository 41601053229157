define('debentures-web-public/models/data-protection-record', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/customer'], function (exports, _jsonapiModel, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DataProtectionRecord = Ember.Object.extend({
        id: null,
        source: null,
        type: null,
        createdBy: null,
        created: null,
        customer: null,
        value: null,
        clientId: null,
        changeRequesterType: null
    });

    DataProtectionRecord.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'data-protection-records',
            relationships: {
                customer: _customer.default
            }
        }
    });

    exports.default = DataProtectionRecord;
});