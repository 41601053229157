define('debentures-web-public/pods/components/pagination-buttons/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['col-sm-12', 'buttons-bottom'],
        // classNameBindings: [ 'hidden' ],

        hiddenPagination: Ember.computed('pageMeta', function () {
            return Ember.isBlank(this.get('pageMeta')) || this.get('pageMeta.first') && this.get('pageMeta.last');
        }),

        pages: Ember.computed("pageMeta", function () {
            var pageMeta = this.get('pageMeta');
            var pages = [];

            if (pageMeta.totalPages != null) {
                var start = pageMeta.number - 2;
                if (start < 0) {
                    start = 0;
                }
                var end = pageMeta.number + 3;
                if (end > pageMeta.totalPages) {
                    end = pageMeta.totalPages;
                }
                for (var i = start; i < end; i++) {
                    pages.push(i + 1);
                }
            } else {
                pages.push(pageMeta.number + 1);
            }
            return pages;
        }),

        pageNumber: Ember.computed("pageMeta", function () {
            return this.get('pageMeta').number + 1;
        })
    });
});