define('debentures-web-public/pods/my-account/addresses/new/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/customer-address'], function (exports, _environment, _customerAddress) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model() {
            return _customerAddress.default.create({ type: 'MAIN', countryId: 'GB', customerId: this.get('session').get('currentCustomer').id });
        },


        actions: {
            saveAddress: function saveAddress(address) {
                var _this = this;

                return this.get('ajax').post(SERVICES.customers + '/addresses/address', _customerAddress.default.stringify(address), _customerAddress.default).then(function () {
                    return _this.transitionTo('my-account.addresses.index');
                });
            }
        }
    });
});