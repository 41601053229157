define('debentures-web-public/models/booking-fixture-date-ref', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var BookingFixtureDateRef = Ember.Object.extend({
        id: null,
        bookingId: null,
        fixtureDateId: null,
        unallocatedTickets: null,
        unallocatedDining: null
    });

    BookingFixtureDateRef.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-date-refs'
        }
    });

    exports.default = BookingFixtureDateRef;
});