define('debentures-web-public/models/basket-item-customization', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var BasketItemCustomization = Ember.Object.extend(Ember.Copyable, {
        seat: null,
        restaurant: null,
        allocations: null,
        allocated: null,
        allocationStatus: null
    });

    BasketItemCustomization.reopenClass({
        convert: function convert(json) {
            return BasketItemCustomization.create(json);
        },
        convertArray: function convertArray(jsonArray) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item);
            });
        }
    });

    BasketItemCustomization.reopen({
        copy: function copy() {
            return BasketItemCustomization.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = BasketItemCustomization;
});