define('debentures-web-public/models/basket', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/client', 'debentures-web-public/models/customer', 'debentures-web-public/models/booking-group', 'debentures-web-public/utils/collectors'], function (exports, _jsonapiModel, _client, _customer, _bookingGroup, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Basket = Ember.Object.extend({
        id: null,
        client: null,
        partner: null,
        bookingGroups: null,
        billing: null,
        shipping: null,
        discountAmount: null,
        discountPercent: null,
        currency: null,
        total: null,
        customer: null,
        promotionAmount: null,
        promotionCode: null,
        info: null,

        init: function init() {
            this.setProperties({
                bookingGroups: this.get('bookingGroups') || [],
                info: this.get('info') || { notes: null, groupsInfo: {} }
            });
        },


        sortedGroups: Ember.computed('bookingGroups.@each.index', function () {
            return this.get('bookingGroups').sortBy('index');
        }),
        itemsQty: Ember.computed('bookingGroups.{[],@each.bookingGroup}', function () {
            var bookingGroups = this.get('bookingGroups');
            var itemsQty = 0;

            if (Ember.isPresent(bookingGroups)) {
                for (var i = 0; i < bookingGroups.length; i++) {
                    for (var k = 0; k < bookingGroups[i].get('items').length; k++) {
                        itemsQty += bookingGroups[i].get('items')[k].get('quantity');
                    }
                }
            }

            return itemsQty;
        }),

        answersGiven: Ember.computed('bookingGroups.@each.answersGiven', function () {
            var bookingGroups = this.get('bookingGroups');
            var itemsQty = 0;

            if (Ember.isPresent(bookingGroups)) {
                for (var i = 0; i < bookingGroups.length; i++) {
                    if (!bookingGroups[i].answersGiven) {
                        return false;
                    }
                }
                return true;
            }

            return false;
        }),

        additionalItemsQty: Ember.computed('bookingGroups.{[],@each.bookingGroup}', function () {
            var bookingGroups = this.get('bookingGroups');
            var additionalItemsQty = 0;

            if (Ember.isPresent(bookingGroups)) {
                for (var i = 0; i < bookingGroups.length; i++) {
                    additionalItemsQty += bookingGroups[i].get('groupInfo.additionalTickets') || 0;
                }
            }
            return additionalItemsQty;
        }),

        hasProducts: Ember.computed('itemsQty', function () {
            return this.get('itemsQty') > 0 || this.get('additionalItemsQty') > 0;
        }),
        groupItems: Ember.computed('bookingGroups.@each.index', function () {
            return (0, _collectors.flatMap)(this.get('bookingGroups'), function (bg) {
                return bg.get('items');
            });
        }),

        fromJSON: function fromJSON() {
            var groupsInfo = this.get('info.groupsInfo');

            var bookingGroups = this.get('bookingGroups');
            if (Ember.isPresent(bookingGroups)) {
                this.set('bookingGroups', _bookingGroup.default.convertArray(bookingGroups, groupsInfo));
            }

            return this;
        }
    });

    Basket.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'baskets',
            relationships: {
                client: _client.default,
                customer: _customer.default
            }
        }
    });

    exports.default = Basket;
});