define('debentures-web-public/pods/activate-customer/activation-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'form',
        ariaRole: 'form',

        submit: function submit() {
            var _this = this;

            if (this.get('inProgress')) {
                return false;
            }

            this.set('inProgress', true);

            var passwordObj = this.getProperties('newPassword', 'confirmPassword');
            this.get('activateCustomer')(passwordObj).catch(function (errors) {
                _this.set('errors', errors);

                return errors;
            }).finally(function () {
                return _this.set('inProgress', false);
            });

            return false;
        }
    });
});