define('debentures-web-public/pods/my-account/sub-menu/component', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var rootURL = _environment.default.rootURL,
        returnURL = _environment.default.returnURL,
        editUserURL = _environment.default.editUserURL;
    exports.default = Ember.Component.extend({
        rootURL: rootURL,
        returnURL: returnURL,
        editUserURL: editUserURL,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('encodedReturnUrl', encodeURIComponent(this.get('returnURL') + '#/my-account'));
        }
    });
});