define('debentures-web-public/models/debenture-seat', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/debenture', 'debentures-web-public/models/customer'], function (exports, _jsonapiModel, _debenture, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DebentureSeat = Ember.Object.extend({
        id: null,
        stand: null,
        row: null,
        block: 0,
        seatNumber: 0,
        debenture: null,
        customer: null
    });

    DebentureSeat.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'debenture-seats',
            relationships: {
                debenture: _debenture.default,
                customer: _customer.default
            }
        }
    });

    exports.default = DebentureSeat;
});