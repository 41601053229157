define('debentures-web-public/pods/components/unauthenticated-wrapper/component', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var rootURL = _environment.default.rootURL;
    exports.default = Ember.Component.extend({
        classNames: ['login_body'],

        rootURL: rootURL
    });
});