define('debentures-web-public/services/stripe', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        handler: null,

        setup: function setup() {
            var _this = this;

            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var mergedOptions = Ember.assign({}, options);
            return this._loadStripe().then(function () {
                var handler = Stripe(_environment.default.stripe.key, mergedOptions);

                _this.set('handler', handler);
            });
        },
        _loadStripe: function _loadStripe() {
            var _this2 = this;

            if (this.get('_loadPromise')) {
                return this.get('_loadPromise');
            }

            return this.set('_loadPromise', new Ember.RSVP.Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = false;
                script.src = 'https://js.stripe.com/v3/';

                script.onload = function () {
                    _this2.set('stripeLoaded', true);
                    resolve();
                };
                script.onerror = reject;

                document.body.appendChild(script);
            }));
        },
        redirectToCheckout: function redirectToCheckout() {
            var _this3 = this;

            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            this._loadStripe().then(function () {
                _this3.get('handler').redirectToCheckout(options);
            });
        },
        close: function close() {}
    });
});