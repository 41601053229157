define('debentures-web-public/models/block', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Block = Ember.Object.extend({
        id: null
    });

    Block.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'block'
        }
    });

    exports.default = Block;
});