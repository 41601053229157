define('debentures-web-public/initializers/is-mobile-service-injector', ['exports', 'ismobilejs/initializers/is-mobile-service-injector'], function (exports, _isMobileServiceInjector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isMobileServiceInjector.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _isMobileServiceInjector.initialize;
    }
  });
});