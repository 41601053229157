define('debentures-web-public/pods/book/view-basket/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        basket: Ember.inject.service(),

        _activate: Ember.on('activate', function () {
            this.addObserver('basket.data.hasProducts', this, this.validateBasketState);
        }),
        _deactivate: Ember.on('deactivate', function () {
            this.removeObserver('basket.data.hasProducts', this, this.validateBasketState);
        }),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            return Ember.RSVP.resolve(this._super.apply(this, arguments)).then(function () {
                return _this.get('basket').setup(true);
            }).then(function () {
                return _this.validateBasketState(transition);
            });
        },
        model: function model() {
            return this.get('basket');
        },
        validateBasketState: function validateBasketState(transition) {
            if (!this.get('basket.data.hasProducts')) {
                if (transition.abort) {
                    transition.abort();
                }

                this.transitionTo('book.quick-booking');
            }
        },


        actions: {
            updateRequests: function updateRequests() {
                var _this2 = this;

                var basketInfo = this.get('basket.data.info');
                return this.get('basket').updateInfo(basketInfo).then(function () {
                    return _this2.transitionTo('book.payment-centre');
                });
            }
        }
    });
});