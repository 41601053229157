define('debentures-web-public/models/dining-rights-entry', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DiningRightsEntry = Ember.Object.extend({
        id: null,
        restaurant: null,
        debentureType: null
    });

    DiningRightsEntry.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'dining-rights-entries'
        }
    });

    exports.default = DiningRightsEntry;
});