define('debentures-web-public/pods/my-account/addresses/edit/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/customer-address'], function (exports, _environment, _customerAddress) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({
        ajax: Ember.inject.service(),
        flashMessages: Ember.inject.service(),

        model: function model(params) {
            return this.get('ajax').fetchModel(_customerAddress.default, SERVICES.customers + '/addresses/address/' + params.address_id);
        },
        serialize: function serialize(model) {
            return { address_id: model.get('id') };
        },

        actions: {
            updateAddress: function updateAddress(address) {
                var _this = this;

                return this.get('ajax').patch(SERVICES.customers + '/addresses/address/' + address.get('id'), _customerAddress.default.stringify(address), _customerAddress.default).then(function () {
                    return _this.transitionTo('my-account.addresses.index');
                });
            },
            deleteAddress: function deleteAddress(address) {
                var _this2 = this;

                return this.get('ajax').remove(SERVICES.customers + '/addresses/address/' + address.get('id')).then(function () {
                    return _this2.transitionTo('my-account.addresses.index');
                });
            },
            requestDefault: function requestDefault(address) {
                var _this3 = this;

                return this.get('ajax').post(SERVICES.customers + '/addresses/address-request/' + address.get('id')).then(function () {
                    _this3.get('flashMessages').success('The request to change your default address has been sent to your Account Manager.');
                    window.scrollTo(0, 0);
                });
            }
        }
    });
});