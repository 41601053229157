define('debentures-web-public/pods/fixtures/view-date/booked-tickets/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tickets: Ember.computed('bookingGroup.sortedItems.[]', function () {
            var rights = [];
            var additional = [];

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('bookingGroup.sortedItems')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var item = _step.value;

                    if (!item.get('isTicket') && !item.get('isCancelled') && item.get('quantity') > 0) {
                        continue;
                    }

                    if (item.get('customization.allocated')) {
                        additional.push(item);
                    } else {
                        rights.push(item);
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return {
                rights: rights,
                additional: additional
            };
        }),
        hasAdditionalTickets: Ember.computed('fixtureDateRef.unallocatedTickets', 'tickets.additional.[]', function () {
            return this.get('fixtureDateRef.unallocatedTickets') > 0 || this.get('tickets.additional').length > 0;
        })
    });
});