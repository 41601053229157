define('debentures-web-public/models/checkout', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Checkout = Ember.Object.extend({
        termsAccepted: null,
        status: null,
        devastate: null
    });

    Checkout.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'checkouts'
        }
    });

    exports.default = Checkout;
});