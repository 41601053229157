define('debentures-web-public/models/booking-cancellation', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var BookingCancellation = Ember.Object.extend({
        items: null,
        refund: null
    });

    BookingCancellation.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'booking-cancellations'
        }
    });

    exports.default = BookingCancellation;
});