define('debentures-web-public/pods/book/select-products/booking-group', ['exports', 'debentures-web-public/utils/collectors', 'debentures-web-public/models/basket-item-customization'], function (exports, _collectors, _basketItemCustomization) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var GROUP_TICKETS = 'Tickets';
    var GROUP_DINING = 'Dining';

    var BookingGroup = Ember.Object.extend({
        bookingGroup: null,
        groupInfo: null,
        bookingNotes: null,
        seats: null,
        debentures: null,
        products: null,
        diningRights: null,
        ticketsOnly: false,

        init: function init() {
            this._super.apply(this, arguments);

            // Make sure groupInfo is correctly initialized for new bookingGroup.
            this.set('groupInfo', Ember.assign({
                additionalTickets: 0,
                altRestaurant: null,
                noDiningRequired: false,
                specialRequests: {
                    general: '',
                    dietary: ''
                }
            }, this.get('bookingGroup.groupInfo')));
        },


        isNew: Ember.computed('bookingGroup', function () {
            return !this.get('bookingGroup');
        }),
        items: Ember.computed('bookingGroup.items.[]', function () {
            var result = [];
            var debenturesByStand = this._mapDebenturesByStand();
            var itemsMap = this._mapItems();
            var restaurants = this._getAvailableRestaurants();

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('products')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var p = _step.value;

                    var productGroup = p.get('productGroup');

                    if (GROUP_TICKETS === productGroup) {
                        var debentures = debenturesByStand[p.get('meta.stand')];
                        if (!debentures) {
                            continue;
                        }

                        var _iteratorNormalCompletion2 = true;
                        var _didIteratorError2 = false;
                        var _iteratorError2 = undefined;

                        try {
                            for (var _iterator2 = debentures[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                                var deb = _step2.value;

                                var basketItem = itemsMap[deb.get('seat.id')];

                                result.push(BasketItem.create({
                                    basketItem: basketItem,
                                    product: p,
                                    selected: !!basketItem,
                                    quantity: basketItem && basketItem.get('quantity'),
                                    debenture: deb
                                }));
                            }
                        } catch (err) {
                            _didIteratorError2 = true;
                            _iteratorError2 = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                    _iterator2.return();
                                }
                            } finally {
                                if (_didIteratorError2) {
                                    throw _iteratorError2;
                                }
                            }
                        }
                    } else if (!this.get('ticketsOnly') && GROUP_DINING === productGroup) {
                        if (!restaurants.includes(p.get('meta.restaurant.id'))) {
                            continue;
                        }

                        var _basketItem = itemsMap[p.get('id')];

                        result.push(BasketItem.create({
                            basketItem: _basketItem,
                            product: p,
                            selected: !!_basketItem,
                            quantity: _basketItem && _basketItem.get('quantity')
                        }));
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return result;
        }),

        _mapItems: function _mapItems() {
            var result = {};

            if (!this.get('bookingGroup.items')) {
                return result;
            }

            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = this.get('bookingGroup.items')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var it = _step3.value;

                    var itemKey = GROUP_TICKETS === it.get('productGroup') ? it.get('customization.seat.id') : it.get('product');

                    result[itemKey] = it;
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                        _iterator3.return();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            return result;
        },
        _mapDebenturesByStand: function _mapDebenturesByStand() {
            var seats = (0, _collectors.hashMap)(this.get('seats'));
            var debentures = this.get('debentures').filter(function (it) {
                return seats[it.get('seat.id')];
            });

            return (0, _collectors.groupBy)(debentures, function (it) {
                return it.get('type.stand');
            });
        },
        _getAvailableRestaurants: function _getAvailableRestaurants() {
            if (!this.get('diningRights')) {
                return [];
            }

            var result = new Set();
            var debentureTypes = (0, _collectors.unique)(this.get('debentures'), function (d) {
                return d.get('type.id');
            });

            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
                for (var _iterator4 = this.get('diningRights.entries')[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var right = _step4.value;

                    if (debentureTypes.includes(right.get('debentureType'))) {
                        result.add(right.get('restaurant'));
                    }
                }
            } catch (err) {
                _didIteratorError4 = true;
                _iteratorError4 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                        _iterator4.return();
                    }
                } finally {
                    if (_didIteratorError4) {
                        throw _iteratorError4;
                    }
                }
            }

            return [].concat(_toConsumableArray(result));
        },
        getBasketUpdates: function getBasketUpdates() {
            var result = {
                removedItems: [],
                addedProducts: [],
                updatedItems: []
            };

            var _iteratorNormalCompletion5 = true;
            var _didIteratorError5 = false;
            var _iteratorError5 = undefined;

            try {
                for (var _iterator5 = this.get('items')[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                    var bi = _step5.value;

                    var basketItem = bi.get('basketItem');

                    if (basketItem && !bi.get('selected')) {
                        // Removed items.

                        result.removedItems.push(basketItem.get('id'));
                    } else if (!basketItem && bi.get('selected')) {
                        // Added items.

                        var product = Ember.copy(bi.get('product'), true);

                        if (bi.get('isTicket')) {
                            product.setProperties({
                                _quantity: 1,
                                _customization: _basketItemCustomization.default.create({
                                    seat: bi.get('debenture.seat')
                                })
                            });
                        } else {
                            product.setProperties({
                                _quantity: bi.get('quantity'),
                                _customization: _basketItemCustomization.default.create({
                                    restaurant: product.get('meta.restaurant')
                                })
                            });
                        }

                        result.addedProducts.push(product);
                    } else if (basketItem && bi.get('selected')) {
                        // Updated items.

                        var item = Ember.copy(basketItem, true);

                        // Update dining quantity.
                        if (item.get('quantity') !== bi.get('quantity')) {
                            item.set('quantity', bi.get('quantity'));

                            result.updatedItems.push(item);
                        }
                    }
                }
            } catch (err) {
                _didIteratorError5 = true;
                _iteratorError5 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion5 && _iterator5.return) {
                        _iterator5.return();
                    }
                } finally {
                    if (_didIteratorError5) {
                        throw _iteratorError5;
                    }
                }
            }

            return result;
        },
        putNoneProductIfNeeded: function putNoneProductIfNeeded(result, noneProduct) {
            console.log("put none product if needed");

            var additionalTickets = this.get("groupInfo.additionalTickets");
            var noneBI = this.get("isNew") ? null : this.get('bookingGroup.items').findBy('productType', 'NONE');
            var debentureSelected = this.get('items').findBy('selected', true);

            var isExtrasOnlyBooking = additionalTickets > 0 && !debentureSelected;

            if (isExtrasOnlyBooking && !noneBI) {
                var noneProductCopy = Ember.copy(noneProduct, true);
                noneProductCopy.setProperties({
                    _quantity: 1,
                    _customization: _basketItemCustomization.default.create()
                });
                result.addedProducts.push(noneProductCopy);
            } else if (!isExtrasOnlyBooking && noneBI) {
                result.removedItems.push(noneBI.get('id'));
            }
        },
        updatedBasketInfo: function updatedBasketInfo(basketInfo, fixtureDateId) {
            var groupInfo = this.get('groupInfo');

            // Reset dietary requests if no dining products selected.
            var hasNoDiningSelected = !this.get('items').any(function (bi) {
                return bi.get('isDining') && bi.get('selected');
            });
            if (hasNoDiningSelected) {
                Ember.set(groupInfo.specialRequests, 'dietary', '');
            }

            // Keep only relevant restaurant details.
            var altRestaurant = groupInfo.altRestaurant;
            if (altRestaurant) {
                Ember.set(groupInfo, 'altRestaurant', {
                    id: altRestaurant.id,
                    name: altRestaurant.name,
                    stand: altRestaurant.stand
                });
            }

            basketInfo.notes = this.get('bookingNotes');
            basketInfo.groupsInfo[fixtureDateId] = groupInfo;

            return basketInfo;
        }
    });

    var BasketItem = Ember.Object.extend({
        basketItem: null,
        debenture: null,
        product: null,
        quantity: null,
        selected: null,

        isTicket: Ember.computed('product.productGroup', function () {
            return this.get('product.productGroup') === GROUP_TICKETS;
        }),
        isDining: Ember.computed('product.productGroup', function () {
            return this.get('product.productGroup') === GROUP_DINING;
        }),
        isNone: Ember.computed('product.productType', function () {
            return this.get('product.productType') === 'NONE';
        })
    });

    exports.default = BookingGroup;
});