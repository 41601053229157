define('debentures-web-public/models/transaction', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Transaction = Ember.Object.extend(Ember.Copyable, {
        id: null,
        parent: null,
        type: null,
        orderId: null,
        itemsGroup: null,
        created: null,
        reference: null,
        cardScheme: null,
        paymentMethod: null,
        amount: null,
        currency: null,
        status: null,
        error: null,

        isRefund: Ember.computed('type', function () {
            return this.get('type') === 'REFUND';
        }),

        toJSON: function toJSON() {
            var result = Ember.copy(this, true);
            result.set('amount', Ember.isBlank(this.get('amount')) ? null : Number(this.get('amount')));
            return result;
        }
    });

    Transaction.reopen({
        copy: function copy() {
            return Transaction.create(this.getProperties(Object.keys(this)));
        }
    });

    Transaction.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'transactions',
            relationships: {
                parent: Transaction
            }
        }
    });

    exports.default = Transaction;
});