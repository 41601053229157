define('debentures-web-public/models/fixture-date-service', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var FixtureDateService = Ember.Object.extend({
        id: null,
        firstSitting: null,
        flexibleSitting: null,
        lastSitting: null,
        name: null,
        restaurantId: null,
        restaurantName: null,
        service: null,
        settings: null
    });

    FixtureDateService.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'fixture-restaurant-services'
        }
    });

    exports.default = FixtureDateService;
});