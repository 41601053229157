define('debentures-web-public/router', ['exports', 'debentures-web-public/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('404', { path: '/*wildcard' });

    this.route('login');
    this.route('logout');
    this.route('forgotten-password');
    this.route('activate-customer', { path: '/activate-customer/:code' });

    this.route('fixtures', function () {
      this.route('view-date', { path: '/:fixture_date_id' });
    });

    this.route('bookings', function () {
      this.route('view', { path: '/:booking_id' });
    });

    this.route('my-account', function () {
      this.route('debentures');
    });

    this.route('payment-callback');

    this.route('book', function () {
      this.route('select-products', { path: '/:fixture_date_id' });
      this.route('view-basket');
      this.route('payment-centre');
      this.route('quick-booking', function () {});
      this.route('resale', function () {});
    });
    this.route('privacy-notice');
    this.route('cookie-policy');
    this.route('holding-page');
    this.route('dining');
  });

  exports.default = Router;
});