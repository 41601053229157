define('debentures-web-public/models/dining-quantity-change', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DiningQuantityChange = Ember.Object.extend({
        quantity: null
    });

    DiningQuantityChange.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'dining-quantity-change'
        }
    });

    exports.default = DiningQuantityChange;
});