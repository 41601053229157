define('debentures-web-public/pods/my-account/password/route', ['exports', 'debentures-web-public/config/environment', 'debentures-web-public/models/customer'], function (exports, _environment, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend({

        ajax: Ember.inject.service(),
        flashMessages: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model() {
            return Ember.copy(this.get('session').get('currentCustomer'), true);
        },


        actions: {
            update: function update(customer) {
                var _this = this;

                this.set('currentModel.errors', null);

                var data = _customer.default.stringify({
                    currentPassword: customer.get('currentPassword'),
                    confirmPassword: customer.get('confirmPassword'),
                    newPassword: customer.get('newPassword')
                });

                this.get('ajax').patch(SERVICES.customers + '/customers/' + customer.id, data, _customer.default).then(function () {
                    _this.get('flashMessages').success('Password successfully updated');
                }).catch(function (errors) {
                    //this.get('flashMessages').danger('Please fill in the form correctly');
                    _this.set('currentModel.errors', errors);
                });
                //window.scrollTo(0, 0);
            }
        }
    });
});