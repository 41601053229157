define('debentures-web-public/pods/my-account/address-form/component', ['exports', 'ember-i18n-iso-countries'], function (exports, _emberI18nIsoCountries) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modal: Ember.inject.service(),

        isNew: Ember.computed('address', function () {
            return Ember.isEmpty(this.get('address.id'));
        }),

        countries: Ember.computed(function () {
            return (0, _emberI18nIsoCountries.getNames)('en');
        }),

        actions: {
            saveAddress: function saveAddress() {
                var _this = this;

                this.set('errors', null);
                this.get('saveAddress')(this.get('address')).catch(function (errors) {
                    _this.set('errors', errors);
                    return errors;
                });
            },
            deleteAddress: function deleteAddress() {
                var _this2 = this;

                this.get('modal').showConfirmation('Delete Confirmation', 'Are you sure you would like to delete this address?', function () {
                    return _this2.get('deleteAddress')(_this2.get('address'));
                });
            },
            requestDefault: function requestDefault() {
                var _this3 = this;

                this.get('modal').showConfirmation('Request Confirmation', 'Are you sure you would like to request this address to be your default address?', function () {
                    return _this3.get('requestDefault')(_this3.get('address'));
                });
            }
        }
    });
});