define('debentures-web-public/models/temporal-media', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var TemporalMedia = Ember.Object.extend({
        id: null,
        url: null,
        thumbnailUrl: null
    });

    TemporalMedia.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'temporal-media'
        }
    });

    exports.default = TemporalMedia;
});