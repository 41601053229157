define('debentures-web-public/models/template', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Template = Ember.Object.extend({
        id: null,
        clientId: null,
        name: null,
        body: null,
        subject: null
    });

    Template.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'template'
        }
    });

    exports.default = Template;
});