define('debentures-web-public/pods/components/x-select/component', ['exports', 'emberx-select/components/x-select'], function (exports, _xSelect) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _xSelect.default.extend({
        attributeBindings: ['data-title', 'data-style', 'data-selected-text-format', 'data-count-selected-text'],

        optionsUpdated: Ember.observer('options.[]', function () {
            var _this = this;

            this._handleBootstrapSelectAction(function () {
                _this.$().selectpicker('refresh');
            });
        }),

        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this._super.apply(this, arguments);

            this._handleBootstrapSelectAction(function () {
                _this2.$().selectpicker();

                if (Ember.isNone(_this2.get('data-title'))) {
                    // If prompt title is not set - select first option by default.

                    _this2.$().selectpicker('val', _this2.get('options')[0].get('value'));
                } else {
                    _this2.$().selectpicker('val', _this2.get('value'));
                }
            });
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            this.$().selectpicker('destroy');
        },
        _handleBootstrapSelectAction: function _handleBootstrapSelectAction(fn) {
            if (this.$().hasClass('selectpicker')) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                    fn && fn();
                });
            }
        }
    });
});