define('debentures-web-public/models/debenture-type', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DebentureType = Ember.Object.extend({
        id: null,
        name: null,
        stand: null,
        standName: null,

        labelClass: Ember.computed('id', function () {
            if (this.get('id') === 'MSG') {
                return 'label-warning';
            } else if (this.get('id') === 'CSB' || this.get('id') === 'ESB') {
                return 'label-info';
            } else if (this.get('id') === 'GSS' || this.get('id') === 'MSS') {
                return 'label-default';
            } else if (this.get('id') === 'GSB') {
                return 'label-bronze';
            } else {
                return '';
            }
        }),

        label: Ember.computed('id', function () {
            if (this.get('id') === 'MSG') {
                return 'Mound Stand Gold';
            } else if (this.get('id') === 'CSB') {
                return 'Compton Stand';
            } else if (this.get('id') === 'ESB') {
                return 'Edrich Stand';
            } else if (this.get('id') === 'GSS') {
                return 'Grand Stand Silver';
            } else if (this.get('id') === 'MSS') {
                return 'Mound Stand Silver';
            } else if (this.get('id') === 'GSB') {
                return 'Grand Stand Bronze';
            } else {
                return '';
            }
        })
    });

    DebentureType.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'debenture-types'
        }
    });

    exports.default = DebentureType;
});