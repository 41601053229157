define('debentures-web-public/helpers/remainder', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        compute: function compute(params) {
            return parseInt(params[0]) % parseInt(params[1]);
        }
    });
});