define('debentures-web-public/models/dining-rights', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/dining-rights-entry'], function (exports, _jsonapiModel, _diningRightsEntry) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DiningRights = Ember.Object.extend({
        id: null,
        name: null,
        enabled: null,
        entries: null,

        statusClass: Ember.computed('enabled', function () {
            return this.get('enabled') ? 'is-active' : 'not-active';
        }),
        statusTextClass: Ember.computed('enabled', function () {
            return this.get('enabled') ? 'green' : 'grey';
        })
    });

    DiningRights.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'dining-rights',
            relationships: {
                entries: _diningRightsEntry.default
            }
        }
    });

    exports.default = DiningRights;
});