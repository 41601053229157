define('debentures-web-public/pods/login/login-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'form',
        ariaRole: 'form',
        elementId: 'sf-login-form',

        submit: function submit() {
            var _this = this;

            if (this.get('inProgress')) {
                return false;
            }

            this.set('error', '');
            this.set('inProgress', true);

            this.get('authenticate')(this.getProperties('username', 'password')).catch(function (error) {
                if (!error || error.hasOwnProperty('status')) {
                    error = 'Invalid email address or password';
                }

                _this.set('error', error);
                _this.set('password', '');
                _this.set('inProgress', false);
            });

            return false;
        }
    });
});