define('debentures-web-public/pods/bookings/view/booking-items/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        fixturesCount: Ember.computed('booking.bookingGroups', function () {
            return this.get('booking.bookingGroups') != null ? this.get('booking.bookingGroups').length : 0;
        })
    });
});