define('debentures-web-public/helpers/search-counter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        compute: function compute(params) {
            var num = params[0].number;
            var size = params[0].size;
            var max = params[0].totalRecord;

            var first = num * size + 1;
            var last = Math.min((num + 1) * size, max);

            return first + ' - ' + last + ' of ' + max;
        }
    });
});