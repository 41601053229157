define('debentures-web-public/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend({
        session: service(),

        redirect: function redirect() {
            if (this.get('session.isAuthenticated')) {
                this.transitionTo('book.quick-booking');
                //this.transitionTo('my-account.debentures');
            } else {
                this.transitionTo('login');
            }
        }
    });
});