define('debentures-web-public/pods/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'debentures-web-public/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES,
        rootURL = _environment.default.rootURL;
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),
        /*ajax: inject(),
         queryParams: {
            token: { refreshModel: true }
        },*/

        actions: {
            authenticate: function authenticate(credentials) {

                return this.get('session').authenticateCustomer(credentials);
            }
        }
        /*model(params, transition) {
            if (!params.token) {
                const samlService = SERVICES.customers + '/saml/';
                this.get('ajax').rawRequest(samlService + 'auth', {method: 'GET'})
                    .then(formData => {
                        window.location.replace(formData.redirectUrl);
                    }).catch(errors => {
                    Ember.Logger.info("Error");
                    Ember.Logger.warn(errors);
                    this.set('currentModel.errors', errors);
                    return errors;
                });
            } else {
                this.get('session').authenticateCustomerWithToken(params.token, true);
            }
        }*/
    });
});