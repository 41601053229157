define('debentures-web-public/models/restaurant-package', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'debentures-web-public/models/restaurant-service'], function (exports, _jsonapiModel, _restaurantService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var RestaurantPackage = Ember.Object.extend({
        id: null,
        name: null,
        description: null,
        iconUrl: null,
        services: null
    });

    RestaurantPackage.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'restaurant-packages',
            relationships: {
                services: _restaurantService.default
            }
        }
    });

    exports.default = RestaurantPackage;
});