define('debentures-web-public/pods/forgotten-password/route', ['exports', 'debentures-web-public/config/environment', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'debentures-web-public/models/password-request'], function (exports, _environment, _unauthenticatedRouteMixin, _passwordRequest) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var SERVICES = _environment.default.SERVICES;
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        ajax: Ember.inject.service(),

        actions: {
            sendRequest: function sendRequest(username) {
                var _this = this;

                var passwordRequest = _passwordRequest.default.create({
                    email: username,
                    client: _environment.default.clientId
                });
                var requestData = _passwordRequest.default.stringify(passwordRequest);

                return this.get('ajax').post(SERVICES.customers + '/auth/password-requests', requestData).then(function () {
                    Ember.run.later(function () {
                        return _this.transitionTo('login');
                    }, 5000);
                });
            }
        }
    });
});