define('debentures-web-public/pods/forgotten-password/forgotten-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'form',
        ariaRole: 'form',

        submit: function submit() {
            var _this = this;

            if (this.get('inProgress')) {
                return false;
            }

            this.set('inProgress', true);
            this.set('userNotFound', false);
            this.set('isSuccessful', false);

            this.get('sendRequest')(this.get('username')).then(function () {
                return _this.set('isSuccessful', true);
            }).catch(function (errors) {
                _this.set('userNotFound', true);

                return errors;
            }).finally(function () {
                return _this.set('inProgress', false);
            });

            return false;
        }
    });
});