define('debentures-web-public/pods/book/quick-booking/mobile-fixture-row/component', ['exports', 'debentures-web-public/utils/range'], function (exports, _range) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        flashMessages: Ember.inject.service(),
        settings: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            var maxAdditionalSeats = this.get('settings.globals.maxAdditionalSeats');

            this.set('additionalQuantities', (0, _range.range)(1, maxAdditionalSeats));
        },


        bookingGroup: Ember.computed('fixtureDate.booking', function () {
            var fixtureDate = this.get('fixtureDate');
            return fixtureDate.get('booking.bookingGroups').findBy('bookingGroup', fixtureDate.get('id'));
        }),
        bookingGroupTicketItems: Ember.computed('bookingGroup.items.[]', function () {
            var bookingGroup = this.get('bookingGroup');

            return !bookingGroup ? [] : bookingGroup.get('items').filter(function (it) {
                return it.get('isTicket') && !it.get('isCancelled');
            });
        }),
        debentureSeatsCount: Ember.computed('bookingGroup', 'bookingGroupTicketItems.[]', function () {
            return this.get('bookingGroupTicketItems').filter(function (it) {
                return !it.get('customization.allocated');
            }).length;
        }),
        additionalSeatsCount: Ember.computed('bookingGroup', 'bookingGroupTicketItems.[]', function () {
            var bookingGroup = this.get('bookingGroup');
            var allocatedCount = this.get('bookingGroupTicketItems').filter(function (it) {
                return it.get('customization.allocated');
            }).length;

            return allocatedCount + bookingGroup.get('groupInfo.additionalTickets');
        }),

        isNoSeatSelected: function isNoSeatSelected(bookingGroup) {
            /*let additionalTickets = bookingGroup.get('groupInfo.additionalTickets') || 0;
            if (additionalTickets > 0) {
                return false;
            }*/

            return bookingGroup.get('items').filter(function (it) {
                return it.get('selected');
            }).length === 0;
        },


        actions: {
            addOrUpdateTickets: function addOrUpdateTickets(fixtureDate) {
                var _this = this;

                if (this.get('isUpdatingBasket')) {
                    return;
                }

                var bookingGroup = fixtureDate.get('bookingGroup');

                if (this.isNoSeatSelected(bookingGroup)) {
                    this.set('errors', {
                        noSeatsSelected: true
                    });
                    return;
                }

                this.setProperties({
                    isUpdatingBasket: true,
                    errors: null
                });

                this.get('updateGroupInBasket')(fixtureDate.get('id'), bookingGroup).then(function () {
                    return _this.get('flashMessages').success('Quantities updated');
                }).catch(function (errors) {
                    return _this.set('errors', errors);
                }).finally(function () {
                    return _this.set('isUpdatingBasket', false);
                });
            }
        }
    });
});