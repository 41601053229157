define('debentures-web-public/pods/components/fixture-search-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'form',
        ariaRole: 'form',

        settings: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            this.set('searchCriteria', {});
        },


        actions: {
            submit: function submit() {
                var searchCriteria = this.get('searchCriteria');
                if (searchCriteria.competition) {
                    searchCriteria.competition = searchCriteria.competition.name;
                }
                this.get('searchAction')(this.get('searchCriteria'));
            }
        }
    });
});