define('debentures-web-public/pods/logout/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

        session: Ember.inject.service(),

        redirect: function redirect() {
            this.get('session').invalidate();
        }
    });
});